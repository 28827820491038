import { graphqlOperation } from "@aws-amplify/api-graphql";
import { API } from "aws-amplify";
import { useState } from "react";
import {
  GetFarmerOrderByIdQuery,
  GetOrderByIdQuery,
  NestedFarmerOrders,
  NestedOrderItem,
  NestedOrders,
  SearchFarmerOrdersQuery,
  SearchOrdersQuery,
} from "../../../API";
import {
  getFarmerOrderById,
  getOrderById,
  searchFarmerOrders,
  searchOrders,
} from "../../../graphql/queries";
import { addCognitoAuth } from "../../../helpers/helpers";
import { AuthRouteProps } from "../../../layouts/layouts.model";
import { GraphQLResult } from "@aws-amplify/api";
import { String } from "lodash";
import { Moment } from "moment";
import moment from "moment";

interface UseOrderType extends AuthRouteProps {}

type FilterType = {
  dmaId: string | undefined;
  limit?: number;
  page?: number;
  q?: string;
  paymentStatus: string;
  creationDate: string | Moment,
  orderStatus: string;
  paymentMethod: string;
  origin: string;
  deliveryType: string;
  fromDate: string | null;
  toDate: string | null;
  farmerName: string;
};

type DmaFilterType = {
  dmaId: string | undefined;
  limit?: number;
  page?: number;
  q?: string;
  creationDate: string | Moment,
  orderStatus: string;
  paymentMethod: string;
  paymentStatus: string;
};

type DestructuredType = SearchFarmerOrdersQuery["searchFarmerOrders"];

const user = JSON.parse(localStorage.getItem("user") || "{}");
const id = user.username;
const dmaObject = JSON.parse(localStorage.getItem("dma") || "{}");
const dmaId = dmaObject?.data?.dmaDetail?.dmaId;

export const useOrder = () => {

  const defaultDmaFilter = {
    limit: 100,
    page: 1,
    creationDate: '',
    orderStatus: "Select Order Status",
    paymentStatus: "Select Payment Status",
    paymentMethod: "Select Payment Method",
    dmaId,
  };

  //state
  const [farmersOrder, setFarmersOrder] = useState<DestructuredType>(
    {} as DestructuredType
  );

  const [dmaOrder, setDmaOrder] = useState<SearchOrdersQuery["searchOrders"]>(
    {} as SearchOrdersQuery["searchOrders"]
  );
  const [dmaOrderDetails, setDmaOrderDetails] = useState<
  GetOrderByIdQuery["getOrderById"]
  >({} as GetOrderByIdQuery["getOrderById"]);
  const [farmerDetails, setFarmerDetails ] = useState<
    GetFarmerOrderByIdQuery["getFarmerOrderById"]
  >({} as GetFarmerOrderByIdQuery["getFarmerOrderById"]);
  const [isFetchingFarmer, setFetchingFarmer] = useState(false);
  const [isFetchingDetails, setFetchingDetails] = useState(false);
  const [isFetchingOrderDetails, setFetchingOrderDetails] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState<Moment | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<Moment | null>(null);
  const [farmers, setFarmers] = useState<any>([]);
  const [filters, setFilters] = useState<FilterType>({
    dmaId,
    limit: 20,
    page: 1,
    creationDate: '',
    paymentStatus: "Select Payment Status",
    orderStatus: "Select Order Status",
    paymentMethod: "Select Payment Method",
    origin: "Select Order Origin",
    deliveryType: "Select Delivery Type",
    fromDate: '',
    toDate: '',
    farmerName: 'Select Farmer',
  });
  const [dmaFilters, setDmaFilters] = useState<DmaFilterType>(defaultDmaFilter);
  const [total, setTotal] = useState<number | null | undefined>(0);
  const [fetchingDmaOrder, setFetchingDmaOrder] = useState(false);
  const [aggregations, setAggregations] = useState<any>([]);
  const [farmerOrderAggregations, setFarmerOrderAggregations] = useState<any>([]);

  //Func to fetch all orders a DMA has from a farmer

  const fetchFarmer = async (branchId?: string) => {
    setFetchingFarmer(true);
    let params: Record<string, any> = {};
    Object.entries(filters).forEach(([key, val]) => {
      if (val && val !== "" && !val.toString().includes("Select")) {
        params[key] = val;
      }
    });
    try {
      const getBranchDetails = JSON.parse(localStorage.getItem('dma') || '{}');
      const q = graphqlOperation(searchFarmerOrders, {
        ...params
      });
      const { data } = (await API.graphql(
        addCognitoAuth(q)
      )) as GraphQLResult<SearchFarmerOrdersQuery>;
      const aggregations: Record<string,Record<string, any>>  = JSON.parse(data?.searchFarmerOrders?.aggregations!);
      if (farmerOrderAggregations.length === 0) {
        setFarmers(aggregations?.farmerName.buckets?.map((buck: Record<string, string>) => buck.key))
        setFarmerOrderAggregations(aggregations)
      }
      setFarmersOrder(data?.searchFarmerOrders);
      setTotal(data?.searchFarmerOrders?.totalCount);
      setFetchingFarmer(false);
    } catch (error) {
      console.log({ error });
      setFetchingFarmer(false);
    }
  };

  //Fetch all dma order
  const fetchDmaOrder = async (query: string) => {
    const getBranchDetails = JSON.parse(localStorage.getItem('dma') || '{}');

    setFetchingDmaOrder(true);
    let params: Record<string, any> = {};
    Object.entries(dmaFilters).forEach(([key, val]) => {
      if (val && val !== "" && !val.toString().includes("Select")) {
        params[key] = val;
      }
      if (key === 'creationDate' && val !== "") {
        params[key] = moment(dmaFilters.creationDate).format('YYYY-MM-DD');
      }
    });
    try {
      const q = graphqlOperation(searchOrders, {
        q: query,
        ...params,
        limit: 100,
        dmaId: getBranchDetails?.data.dmaDetail?.dmaId,
      });
      const { data } = (await API.graphql( 
        addCognitoAuth(q)
      )) as GraphQLResult<SearchOrdersQuery>;
      setDmaOrder(data?.searchOrders);
      setTotal(data?.searchOrders?.totalCount);
      if (aggregations.length === 0) {
        setAggregations(JSON.parse(data?.searchOrders?.aggregations!))
      }
      setFetchingDmaOrder(false);
    } catch (error) {
      console.log({ error });
      setFetchingDmaOrder(false);
    }
  };
  const mapProductsToTableData = (orders: any[] ) =>
    orders &&
    orders.map((order) => {
      return {
        id: order?.farmerOrderId || "",
        name: order?.farmerName || "",
        date: order?.creationDate || "",
        status: order?.paymentStatus,
        total: `Ksh ${order?.totalCost }`|| "",
        paymentMethod: order?.paymentMethod,
        mpesaTransactionId: order?.mpesaTransactionId,
        origin: order?.origin,
        deliveryType: order?.deliveryType
      };
    });

  const mapOrderToTableData = (orders: Array<NestedOrders | null | undefined>) =>
    orders &&
    orders.map((order, index) => {
      const sumOrders =()=>{
          return order?.orderItems?.reduce((acc, order)=>( acc += order?.total!),0)
      }
      return {
        key: index + 1,
        id: order?.orderId || "",
        date: order?.creationDate || "",
        status: order?.orderStatus,
        payment_method: order?.paymentMethod || "",
        payment_status: order?.paymentStatus || "",
        manufacturerId: order?.manufacturerId || "",
        company_name: order?.company_name || "",
        orderItem: `Ksh ${sumOrders()}`,
        rebates: `Ksh ${order?.rebates || 0}`,
        mpesaTransactionId: order?.mpesaTransactionId
      };
    });

  //default filter
  const defaultFilter = {
    limit: 20,
    page: 1,
    creationDate: '',
    paymentStatus: "Select Status",
    paymentMethod: "Select Payment Method",
    orderStatus: "Select Order Method",
    origin: "Select Order Origin",
    deliveryType: "Select Delivery Type",
    fromDate: '',
    toDate: '',
    farmerName: 'Select Farmer',
    dmaId,
  };

  const getFarmerDetails = async (farmerOrderId: string) => {
    try {
      setFetchingDetails(true);
      const q = graphqlOperation(getFarmerOrderById, { farmerOrderId });
      const { data } = (await API.graphql(
        addCognitoAuth(q)
      )) as GraphQLResult<GetFarmerOrderByIdQuery>;
      setFarmerDetails(data?.getFarmerOrderById);
      setFetchingDetails(false);
    } catch (error) {
      console.log({ error });
      setFetchingDetails(false);
    }
  };

  //function to get dma order by id
  const getDmaOrderDetails = async (orderId: string, manufacturerId: string) => {
    try {
      setFetchingOrderDetails(true);
      const q = graphqlOperation(getOrderById, { orderId, manufacturerId });
      const { data } = (await API.graphql(
        addCognitoAuth(q)
      )) as GraphQLResult<GetOrderByIdQuery>;
      setDmaOrderDetails(data?.getOrderById);
      setFetchingOrderDetails(false);
    } catch (error) {
      console.log({ error });
      setFetchingOrderDetails(false);
    }
  };

  const sumOrders =()=>{
      return dmaOrderDetails?.orderItems?.reduce((acc, order)=>( acc += order?.total!),0)
  }

  //func to handle filter change event for farmer order status
  const handleSelectChange = (value: string) => {
    setFilters({ ...filters, paymentStatus: value });
  };

   //func to handle filter change event for farmer order origin
   const handleOriginChange = (value: string) => {
    setFilters({ ...filters, origin: value });
  };

  //func to handle filter change event for farmer order delivery type
  const handleDeliveryTypeChange = (value: string) => {
    setFilters({ ...filters, deliveryType: value });
  };

  const handleStartDateChange = async (date: any) => {
    setStartDateFilter(date);
    setFilters({ ...filters, fromDate: date && moment(date).format('YYYY-MM-DD') });
  };

  const handleEndDateChange = async (date: any) => {
      setEndDateFilter(date);
      setFilters({ ...filters, toDate: date && moment(date).format('YYYY-MM-DD') });
  };

  //func to handle filter change event for farmer
  const handleFarmerChange = (value: string) => {
    setFilters({ ...filters, farmerName: value });
  };

  //func to handle filter change event for farmer order delivery type
  const handleOrderStatusChange = (value: string) => {
    setFilters({ ...filters, orderStatus: value });
  };

    //func to handle filter change event for farmer order status
    const handleDmaSelectChange = (value: string) => {
      setDmaFilters({ ...dmaFilters, orderStatus: value });
    };
    const handleDateChange = (date: any, value: string) => {
      setDmaFilters({ ...dmaFilters, creationDate: moment(value) });
    };

    const handleDmaPaymentChange = (value: string) => {
      setDmaFilters({ ...dmaFilters, paymentStatus: value });
    };
  

    //func to handle pagnination of dma orders
  const handlePageChange = (value: number) => {
    setDmaFilters({ ...dmaFilters, page: value });
  };

    //func to handle pagnination of dma orders
    const handleFarmerPageChange = (value: number) => {
      setFilters({ ...filters, page: value });
    };
  return {
    fetchFarmer,
    isFetchingFarmer,
    total,
    mapProductsToTableData,
    farmersOrder,
    getFarmerDetails,
    farmerDetails,
    filters,
    sumOrders,
    handleSelectChange,
    setFilters,
    defaultFilter,
    fetchDmaOrder,
    handleDmaPaymentChange,
    fetchingDmaOrder,
    isFetchingDetails,
    defaultDmaFilter,
    dmaFilters,
    setDmaFilters,
    mapOrderToTableData,
    dmaOrder,
    handleDmaSelectChange,
    handlePageChange,
    handleFarmerPageChange,
    getDmaOrderDetails,
    isFetchingOrderDetails,
    dmaOrderDetails,
    aggregations,
    handleDateChange,
    handleOriginChange,
    handleDeliveryTypeChange,
    handleOrderStatusChange,
    startDateFilter,
    endDateFilter,
    handleStartDateChange,
    handleEndDateChange,
    handleFarmerChange,
    farmers,
    farmerOrderAggregations,
  };
};