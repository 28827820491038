import React, { Component } from 'react';
import './errorPage.css';
import { Auth } from 'aws-amplify';
class ErrorBoundary extends Component {
  state = { hasError: false, error: '' };

  static getDerivedStateFromError(error: string) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, info: any) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.log({ error, info });
  }

  handleClick = async() => {
    try {
      await Auth.signOut();
      localStorage.clear();
    } catch (e) {
      //  todo: an error occurred
      localStorage.clear();
    }
    window.location.href = '/login';
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container pt-4">
        <div className="row">
            <div className="col-md-12">
                <div className="error-template">
                    <h1>
                        Oops!</h1>
                    <h2>
                        404 Not Found</h2>
                    <div className="error-details">
                        Sorry, an error has occured, Requested page not found!
                    </div>
                    <div className="error-actions">
                        <button type="button" onClick={() => this.handleClick()} className="error-back-btn">
                          Take Me Home 
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
      );
    }
    return this.props.children;
  }
}


export default ErrorBoundary;