import { combineReducers } from 'redux';
import auth from './authReducer';
import { productReducer } from './productReducer';
import { transportReducer } from './transporterReducer';
import { AuthReducerState } from './reducer.model';

export type AppState = {
  auth: AuthReducerState;
};

export default combineReducers({
  auth,
  productReducer,
  transportReducer
});
