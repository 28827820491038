import { useHistory } from 'react-router-dom';

type ProfileButtonProps = {
  handleCloseDrawer?: () => void
}
export const ProfileButton: React.FC<ProfileButtonProps> = ({ handleCloseDrawer }) => {
  const hitory = useHistory();

  const handleClick = () => {
    if (handleCloseDrawer != null) handleCloseDrawer();
    hitory.push("/dma/profile");
  }

  return (
    <div className="profileButton" onClick={() => handleClick()} >
        My Profile
    </div>
  );
};
