import { Views } from "../auth-constants";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
    getDMADetails,
    logout,
  } from "../../../redux/actions/authActions";
import { AuthReducerState } from "../../../redux/reducers/reducer.model";
import useLeftPanelCopy from "../hooks/useLeftPanelCopy";

export const useSignIn = () => {
    const [copy, setCopy, view] = useLeftPanelCopy(Views.Login);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [error, setError] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("");
    const [username, setUsername] = useState("");
  
    const [validating, setValidating] = useState<boolean>(false);
    const [otp, setOtp] = useState<string>("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

  //hooks
  const history = useHistory();
  const dispatch = useDispatch();

  const doAuth = async (auth: AuthReducerState) => {
    try {
      if (auth.isAuthenticated) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (
          cognitoUser &&
          cognitoUser?.challengeName?.toLowerCase() !==
            "new_password_required" &&
          cognitoUser?.attributes?.email_verified
        ) {
          const group: string = cognitoUser?.attributes["custom:group"]
            ? cognitoUser?.attributes["custom:group"]?.toLowerCase()
            : "";
          if(group === 'manufacturer'){
            localStorage.removeItem("authorizationPass");
            localStorage.removeItem("tempUsername");
            return history.push("/manufacturers/dashboard");
          }
          const collectionCentre: string =
            cognitoUser?.attributes["custom:collectionCentreId"];
          const transporter: string =
            cognitoUser?.attributes["custom:branchId"] ?? "";
          const dmaDetails =
            cognitoUser.username && (await getDMADetails(cognitoUser.username));
          if (
            (!dmaDetails?.data?.userDetail ||
            !dmaDetails?.data?.dmaDetail ||
            !dmaDetails?.data?.dmaBranchDetail)
            && group !== 'manufacturer'
          ) {
            dispatch(logout());
            return setError(
              "Failed to fetch the DMA details, kindly contact the administration"
            );
          }
          localStorage.setItem("dma", JSON.stringify(dmaDetails));

          const dmaGroup = dmaDetails?.data?.userDetail?.group;

          const branchUser = dmaDetails?.data?.userDetail?.branchId;

          if (
            (group && group === "dma") ||
            (dmaGroup && dmaGroup === "dma") ||
            branchUser
          ) {
            localStorage.removeItem("authorizationPass");
            localStorage.removeItem("tempUsername");
            return history.push("/dma");
          } else if (!transporter && collectionCentre) {
            return history.push("/dma");
          } else if (!transporter && !["dma", "manufacturer"].includes(group)) {
            dispatch(logout());
            return setError(
              "Transporters can not using the web application, kindly download the transporter application to access the platform"
            );
          } 
        } else if (
          cognitoUser &&
          cognitoUser?.challengeName?.toLowerCase() !==
            "new_password_required" &&
          !cognitoUser?.attributes?.email_verified
        ) {
          setCopy(Views.VerifyEmail);
        } else {
          await Auth.signOut();
          history.push("/login");
          return;
        }
      }
    } catch (e) {
      // he user is not authenticated
      console.log(e);
    }
  };
  

  return {
    copy, 
    setCopy, 
    view,
    isResettingPassword, 
    setIsResettingPassword,
    error, 
    setError,
    deliveryMethod, 
    setDeliveryMethod,
    username, 
    setUsername,
    validating, 
    setValidating,
    otp, 
    setOtp,
    email, 
    setEmail,
    password, 
    setPassword,
    doAuth,
    dispatch
  };
};