import { Col, Nav } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { NavLink } from '../layouts.model';

import logo from '../../assets/LOGO/drawable-xxhdpi/digifarm_logo_approved.png';
import dashboard from '../../assets/SIDENAV ICONS/dashboard.svg';
import dollar from '../../assets/SIDENAV ICONS/dollar.svg';
import statistics from '../../assets/SIDENAV ICONS/statistics.svg';
import './SideNav.css';
import { SideNavItem } from './SideNavItem';
import { useNavItems } from '../useNavItems';
import { useLocation } from "react-router-dom"


export const SideNav = () => {
    const location = useLocation();

    const defaultLinks: NavLink[] = [
        {
            title: 'Dashboard',
            icon: dashboard,
            path: '/manufacturers',
            key: uuid(),
            isSelected: location.pathname === "/manufacturers/dashboard",
        },
        {
            title: 'Sales',
            icon: dollar,
            path: '/manufacturers/sales',
            key: uuid(),
            isSelected: location.pathname === "/manufacturers/sales",
        },
        {
            title: 'Reports',
            icon: statistics,
            path: '/reports',
            key: uuid(),
            isSelected: location.pathname === "/reports",
        },
        {
            title: 'Products',
            icon: dashboard,
            parent: true,
            key: uuid(),
        },
        {
            title: 'Products List',
            icon: dashboard,
            path: '/manufacturers/products',
            child: true,
            key: uuid(),
            isSelected: location.pathname === "/manufacturers/products",
        },
        {
            title: 'Products Upload',
            icon: dashboard,
            path: '/manufacturers/products/upload',
            key: uuid(),
            child: true,
            isSelected: location.pathname === "/manufacturers/products/upload",
        },
        {
            title: 'Hubs',
            icon: dashboard,
            path: '/manufacturers/hubs',
            key: uuid(),
            isSelected: location.pathname === "/manufacturers/hubs",
        },

    ];

    const [links, selectLink] = useNavItems(defaultLinks);

    const closeSidebar = () => {
        const sidebar = document.getElementById('sidenav')!;
        sidebar.classList.remove('opened');
    };

    return (
        <Col
            as={Nav}
            defaultactivekey='/home'
            id='sidenav'
            className='flex-column do-not-print align-items-end border  p-0 side-nav m-0'
        >
            <div className='side-nav-header d-flex justify-content-centers'>
                <img src={logo} alt='digifarm logo'/>

                <button
                    type='button'
                    className='toggle-sidebar close-sidebar'
                    onClick={closeSidebar}
                >
                    <i className='mdi mdi-close'></i>
                </button>
            </div>
            {links.map((link) => (
                <SideNavItem key={link.key} item={link} onSelect={selectLink}/>
            ))}
        </Col>
    );
};
