import { GraphQLResult } from '@aws-amplify/api';
import { addCognitoAuth } from "./../../helpers/helpers";
import { fetchCartByUserId } from "./../../graphql/queries";
import { graphqlOperation, API } from "aws-amplify";
import { Action } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes, SET_ITEM_TO_CART, CLEAR_CART } from "./../actionTypes";
import { FetchCartByUserIdQuery } from '../../API';

type AuthDispatch<T = unknown, S = unknown> = ThunkDispatch<
  T,
  S,
  Action<ActionTypes>
>;

export const getCartCount =
  (id: string | undefined) => async (dispatch: AuthDispatch) => {
    try{
      const q = graphqlOperation(fetchCartByUserId, {
        userId: id,
      });
      const {data} = await API.graphql(addCognitoAuth(q)) as GraphQLResult <FetchCartByUserIdQuery>;

      dispatch({ type: SET_ITEM_TO_CART, payload: data?.fetchCartByUserId });
    }catch(e){
      console.log(e)
    }
  };


  export const updateCartCount =
  (cart: FetchCartByUserIdQuery['fetchCartByUserId']) => async (dispatch: AuthDispatch) => {
    dispatch({ type: SET_ITEM_TO_CART, payload: cart});
  };

  export const clearCartCount =
  () => async (dispatch: AuthDispatch) => {
    dispatch({ type: CLEAR_CART});
  };
