import React, {MouseEvent, useState} from 'react';
import { formatDate } from "../../../utills/utils";
import { NavLink, useHistory } from "react-router-dom";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { addCognitoAuth, validateEmail, validatePhoneNumber } from '../../../helpers/helpers';
import {
    CreateHubInput,
    CreateHubMutation,
    GetManufacturerHubDetailsQuery,
    GetManufacturerHubsQuery,
    HubResponse,
} from "../../../API";
import { toast } from 'react-toastify';
import { API, graphqlOperation } from "aws-amplify";
import { createHub } from "../../../graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getManufacturerHubDetails, getManufacturerHubs } from "../../../graphql/queries";

export interface HubModel {
    hubId: string | null | undefined;
    manufacturerId: string | null | undefined;
    hubName: string | null | undefined;
    email: string | null | undefined;
    phoneNumber: string | null | undefined;
    location: string | null | undefined;
    address: string | null | undefined;
    creationDate: string | null | undefined;
}

type TableData = HubModel[];

export const hubDetailsTableDataColumns = [
    {
        title: 'Name',
        dataIndex: 'hubName',
        key: 'hubName'
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'Date Added',
        dataIndex: 'creationDate',
        key: 'creationDate',
        render: (_: unknown, data: any) => {
            return formatDate(data.creationDate);
        },
    },
    {
        dataIndex: 'action',
        key: 'action',
        render: (action: any, data: any) => {
            const content = (
                <>
                    <NavLink className="d-flex align-items-center" to={`/manufacturers/hubs/${data.hubId}`}>
                        <EyeOutlined /> &nbsp; View More
                    </NavLink>
                </>
            );
            return (
                <Popover placement='leftTop' content={content} trigger='click'>
                    <MoreOutlined />
                </Popover>
            );
        },
    },
];

const createHubsInputInitialState = {
    manufacturerId: '',
    name: '',
    emailAddress: '',
    phoneNumber: '',
    location: '',
    address: ''
}


const mapManufacturerHubsDetailsToTableData = (hubList: Array<HubResponse | null>) => {
    const tableData = hubList?.map((hubDetails) => ({
        key: hubDetails?.hubId,
        hubId: hubDetails?.hubId,
        manufacturerId: hubDetails?.manufacturerId,
        hubName: hubDetails?.hubName,
        email: hubDetails?.email,
        phoneNumber: hubDetails?.phoneNumber,
        location: hubDetails?.location,
        address: hubDetails?.address,
        creationDate: hubDetails?.creationDate?.split('T')[0]
    }))
    return tableData || []
}

export const useHubs = () => {
    const [hubsTableData, setHubsTableData] = useState<TableData>([])
    const [hubDetails, setHubDetails] = useState<HubResponse | undefined | null>()
    const [loadingHubs, setLoadingHubs] = useState(false)
    const [loadingHubDetails, setLoadingHubDetails] = useState(false)
    const [hubInput, setHubInput] = useState<CreateHubInput>(createHubsInputInitialState)
    const [creating, setCreating] = useState(false)
    const history = useHistory()


    const fetchManufacturerHubs = async (manufacturerId: string) => {
        if (!loadingHubs) {
            setLoadingHubs(true)
        }

        const query = graphqlOperation(getManufacturerHubs, { manufacturerId })
        const { data } = (await API.graphql(addCognitoAuth(query))) as GraphQLResult<GetManufacturerHubsQuery>

        const tableData = mapManufacturerHubsDetailsToTableData( data?.getManufacturerHubs?.data || [])
        setHubsTableData(tableData)
        setLoadingHubs(false)
    }

    const fetchManufacturerHubDetails = async (manufacturerId: string, hubId: string) => {
        setLoadingHubDetails(true)

        const query = graphqlOperation(getManufacturerHubDetails, { manufacturerId, hubId });
        const { data } = (await API.graphql(addCognitoAuth(query))) as GraphQLResult<GetManufacturerHubDetailsQuery>;
        if (data?.getManufacturerHubDetails?.code == 200) {
            setHubDetails(data?.getManufacturerHubDetails?.data)
        } else {
            toast.error('Error fetching details')
        }
        setLoadingHubDetails(false)
    }

    const handleHubInputChange = (key: keyof CreateHubInput, value: string) => {
        const hubInputCopy = { ...hubInput }
        setHubInput({ ...hubInputCopy, [key]: value || '' })
    }

    const createHubFn = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const { manufacturerId, name, emailAddress, phoneNumber, location, address } = hubInput
        try {
            if (!manufacturerId || !name || !emailAddress || !phoneNumber || !location || !address) {
                return toast.error('All inputs are required!');
            }

            if (!validateEmail(emailAddress)) {
                return toast.error('Please enter a valid email!');
            }

            if (!validatePhoneNumber(phoneNumber.toString())) {
                return toast.error('Please enter a valid phone number!');
            }

            setCreating(true);

            const query = graphqlOperation(createHub, { hubInput });
            const { data } = (await API.graphql(addCognitoAuth(query))) as GraphQLResult<CreateHubMutation>;

            if (data?.createHub?.code === 200) {
                setTimeout(() => history.push('/manufacturers/hubs'), 3000)
                return toast.success('Hub successfully added!');
            } else {
                return toast.error(data?.createHub?.message);
            }
        } catch (e: any) {
            return toast.error('Error creating Branch', e.message);
        } finally {
            setCreating(false);
        }
    };

    return {
        loadingHubs,
        hubsTableData,
        hubInput,
        creating,
        loadingHubDetails,
        hubDetails,
        handleHubInputChange,
        fetchManufacturerHubs,
        fetchManufacturerHubDetails,
        createHubFn,
    }
}
