export const defaultCurrency = 'KES';

export const formatAmountWithCurrency = (amount?: string | number | null, hideValues = false) => {
    if (hideValues) return 'N/A';
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: defaultCurrency,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(parseInt(`${amount}`));
};

export const getInitials = (name?: string) => {
    if (name === undefined) return 'N/A';
    if (name.includes(' ')) {
        const nameArr = name.split(' ');
        return `${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`;
    } else {
        return name.charAt(0);
    }
};