import '../products.css';

import { Table } from 'antd';
// import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

// import Swal from 'sweetalert2';
import { getInvalidProductsColumns, getUploadColumns } from '../helpers';
// import { useProducts } from './useProducts';

type ProductsUploadedProps = {
  productUploadData: UploadedProducts;
  setView: React.Dispatch<React.SetStateAction<string>>;
  setProductDetailsId: React.Dispatch<React.SetStateAction<string | null>>;
  deleteProduct: (id: string) => Promise<void>;
  setUploadTabKey: React.Dispatch<React.SetStateAction<string>>;
  uploadTabKey: string;
  productDetailsId: string;
};

type InvalidProducts = {
  productId: string | undefined;
  company_name: string | undefined | null;
  type: number | string | null | undefined;
  name: string | null | undefined;
  quantity: number | string | null | undefined;
  category: number | string | null | undefined;
  price: number | string | undefined;
  dateAdded: string | null | undefined;
  status: string | null | undefined;
  unit: number | string | null | undefined;
  creationDate: string | null | undefined;
};

type ValidProducts = {
  productId: string | undefined;
  company_name: string | undefined | null;
  type: number | string | null | undefined;
  name: string | null | undefined;
  quantity: number | string | null | undefined;
  category: number | string | null | undefined;
  price: number | string | undefined;
  dateAdded: string | null | undefined;
  status: string | null | undefined;
  unit: number | string | null | undefined;
  creationDate: string | null | undefined;
};

type UploadedProducts = {
  code: Number;
  status: String;
  failedToBeAddedToDynamoProducts: any[];
  validProducts: ValidProducts[];
  invalidProducts: InvalidProducts[];
  identifiedDuplicateProducts: InvalidProducts[];
};

const ProductUploadList: React.FC<ProductsUploadedProps> = ({
  productUploadData,
  deleteProduct,
  productDetailsId,
  setView,
  setProductDetailsId,
  uploadTabKey,
  setUploadTabKey,
}) => {
  const { validProducts, invalidProducts, identifiedDuplicateProducts } =
    productUploadData as UploadedProducts;
  // const [tableData, setTableData] = useState<TableData>([]);
  const [, setSelectedRow] = useState<Record<string, any>>();

  const [products, setProducts] = useState<ValidProducts[] | []>(validProducts);

  // const { removeProduct } = useProducts();

  const columns = useMemo(
    () =>
      getUploadColumns(
        productDetailsId,
        setView, //eslint-disable-line
        setProductDetailsId,
        deleteProduct
      ),
    []
  );

  useEffect(() => {
    setProducts(validProducts);
  }, [validProducts]);

  const invalidColumns = useMemo(() => getInvalidProductsColumns(), []);

  const handleRow = (row: any) => {
    return {
      onClick: () => setSelectedRow(row),
    };
  };

  const mapProductsToTableData = (
    products: Array<ValidProducts | InvalidProducts | null>
  ) => {
    const mappedProducts = products?.map((product) => {
      return {
        productId: product?.productId || 'N/A',
        company_name: product?.company_name || 'N/A',
        inputName: product?.name,
        quantity: product?.quantity,
        type: product?.type,
        name: product?.name,
        category: product?.category,
        price: product?.price || 'N/A',
        dateAdded: product?.creationDate,
        status: product?.status || 'rejected',
        unit: product?.unit || 'N/A',
        creationDate: product?.creationDate || 'N/A',
      };
    });
    return mappedProducts;
  };

  return (
    <div className='manufacturer' style={{ marginTop: '25px' }}>
      <Tabs
        id='controlled-tab-example'
        activeKey={uploadTabKey}
        onSelect={(k: any) => setUploadTabKey(k)}
      >
        <Tab
          eventKey='valid'
          tabClassName=' uppercase'
          title={`VALID PRODUCTS (${validProducts?.length})`}
          key='1'
        >
          <Table
            id='ant-table'
            style={{ marginTop: '25px' }}
            className='dma-header products-table'
            dataSource={mapProductsToTableData(products)}
            columns={columns}
            onRow={handleRow}
            scroll={{ x: 768 }}
          />
        </Tab>
        <Tab
          eventKey='invalid'
          tabClassName=' uppercase'
          title={`INVALID PRODUCTS (${invalidProducts?.length})`}
          key='2'
        >
          <Table
            id='ant-table'
            style={{ marginTop: '25px' }}
            className='dma-header products-table'
            dataSource={mapProductsToTableData(invalidProducts)}
            columns={invalidColumns}
            // onRow={handleRow}
            scroll={{ x: 768 }}
          />
        </Tab>
        <Tab
          eventKey='duplicate'
          tabClassName=' uppercase'
          title={`DUPLICATE PRODUCTS (${identifiedDuplicateProducts?.length})`}
          key='3'
        >
          <Table
            id='ant-table'
            style={{ marginTop: '25px' }}
            className='dma-header products-table'
            dataSource={mapProductsToTableData(identifiedDuplicateProducts)}
            columns={invalidColumns}
            // onRow={handleRow}
            scroll={{ x: 768 }}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ProductUploadList;
