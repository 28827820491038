import { FC } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NavLink } from "../layouts.model";

import "./SideNavItem.css";

export const SideNavItem: FC<{
  item: NavLink;
  onSelect: (item: NavLink) => void;
}> = ({ item, onSelect }) => {
  if (item.parent) {
    return (
      <div
        className={`${
          item.child ? "sidenav-child" : ""
        } d-block my-3 mx-auto d-flex align-items-center py-2 pl-4 w-100 side-nav-item-link  ${
          item.isSelected ? "selected" : ""
        } ${item.disabled ? "disabledSideNav" : ""}`}
      >
        <div className="col-md-2">
          <img width={18} height={18} src={item.icon} alt="con" />
        </div>

        <Nav.Item className="side-nav-item col-md-10" key={item.key}>
          {item.title}
        </Nav.Item>
      </div>
    );
  }
  return (
    <Link
      className={`${
        item.child ? "sidenav-child" : ""
      } d-block my-3 mx-auto d-flex align-items-center py-2 pl-4 w-100 side-nav-item-link  ${
        item.isSelected ? "selected" : ""
      } ${item.disabled ? "disabledSideNav" : ""}`}
      to={item.path as string}
      onClick={(event) =>
        item.disabled ? event?.preventDefault() : onSelect(item)
      }
    >
      <div className="col-md-2">
        <img width={18} height={18} src={item.icon} alt="con" />
      </div>

      <Nav.Item className="side-nav-item col-md-10" key={item.key}>
        {item.title}
      </Nav.Item>
    </Link>
  );

};
