import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export type RootState = ReturnType<typeof persistedReducer>;

// const logger = createLogger();
const middleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
}).prepend([thunk]);

const store = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware,
    devTools: true,
  });
  let persistor = persistStore(store);
  return { store, persistor };
};

export default store;
