import { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import ErrorBoundary from '../components/ErrorBoundary';
import DmaHeader from '../pages/DMA/components/DmaHeader';
import { Footer } from './Footer';
import { AuthRouteProps } from './layouts.model';
import { isCooperativeMulti } from '../helpers/permissions';
import '../pages/DMA/responsiveness.css';

import { logout } from '../redux/actions/authActions';
const CooperativeMultiPrivateRoute: FC<AuthRouteProps> = ({
  component: Component,
  auth,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = auth?.isAuthenticated;
  const CoopMultiDma = isCooperativeMulti();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && CoopMultiDma) {
          return (
            <ErrorBoundary>
              <DmaHeader />
              <main
                className='dma-bg dma-page-margin min-height-100'
                id='public-route-layout'
              >
                {Component && <Component {...props} />}
              </main>
              <Footer />
            </ErrorBoundary>
          );
        } else {
          dispatch(logout());
          history.push('/login');
        }
      }}
    />
  );
};

const mapStateToProps = ({ auth }: AuthRouteProps) => ({
  auth,
});

export default connect(mapStateToProps)(CooperativeMultiPrivateRoute);
