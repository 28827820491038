import {
  CLEAR_STOCK_COUNT,
  GET_CURRENT_SESSION,
  GET_CURRENT_SESSION_ERROR,
  IS_NOT_VERIFIED,
  LOGIN_MANUFACTURER,
  LOGIN_MANUFACTURER_ERROR,
  LOGIN_MANUFACTURER_SUCCESS,
  LOGOUT,
  RESET_NEW_MANUFACTURER_PASSWORD_ERROR,
  RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS,
    SET_STOCK_COUNT
} from "./../actionTypes";
import { User } from "./../../shared-types";

import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  IS_LOGGING_IN,
  DONE_LOGGING_IN,
  SET_USER,
  LOGOUT_USER,
  ActionTypes,
} from "../actionTypes";

import { GraphQLResult } from "@aws-amplify/api";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { getCartCount } from "./productActions";
import { DmaType, SearchDmastockQuery, SearchDmastockQueryVariables } from "../../API";
import { getDmaUserDetails, searchDmastock } from "../../graphql/queries";
import { addCognitoAuth } from "../../helpers/helpers";

type AuthDispatch<T = unknown, S = unknown> = ThunkDispatch<
  T,
  S,
  Action<ActionTypes>
>;

type StockCountType = {
  bulkUploadProcessId: {
buckets: [
  {key: string, doc_count: number}
]
doc_count_error_upper_bound: number
sum_other_doc_count: number
  }
}

export const setCurrentUser = (user: any) => (dispatch: AuthDispatch) => {
  dispatch({ type: SET_USER, payload: user });
};

export  const fetchDmaStock =  async (params: SearchDmastockQueryVariables | null = { limit: 10, page: 1, q: "" }) => {
  try {
     const getBranchDetails = JSON.parse(
      localStorage.getItem('dma') || '{}'
    );
    const q = graphqlOperation(searchDmastock, {
      ...params,
      dmaId: getBranchDetails?.data.dmaDetail?.dmaId,
      branchId: getBranchDetails?.data?.dmaBranchDetail[0].branchId,
    });
    const { data } = (await API.graphql(
      addCognitoAuth(q)
    )) as GraphQLResult<SearchDmastockQuery>;

    const parsedAggregation = JSON.parse(data?.searchDmastock?.aggregations || "")


    // dispatch({type: "SET_STOCK_COUNT"})
    return { result: parsedAggregation.bulkUploadProcessId?.buckets?.length, message: "search completed" };

  } catch (e) {
    return {
      result: null,
      message: "No data found",
    };
  }
};

export  const getDMADetails =  async (userId: string ) => {
  try {
    const q = graphqlOperation(getDmaUserDetails, {
      userId
    });
    const { data } = (await API.graphql(
      addCognitoAuth(q)
    )) as GraphQLResult<any>;

    const results = data?.getDmaUserDetails;
    localStorage.removeItem('dma');
    localStorage.setItem('dma', JSON.stringify(results));
    return results;

  } catch (e) {
    return {
      result: null,
      message: "No data found",
    };
  }
};

export const verifyEmail = async () => {
    try {
        await Auth.verifyCurrentUserAttribute("email");
    } catch (error) {
        console.log(error);
        // message.info(error)
    }
};

export const loginUser =
  (email: string, password: string) => async (dispatch: AuthDispatch) => {

    dispatch({ type: IS_LOGGING_IN });

    //login logic here.
    try {
      const user = await Auth.signIn({ username: email, password });

      dispatch({ type: DONE_LOGGING_IN });
      dispatch(setCurrentUser(user));
    } catch (e) {
      // todo
      console.log(e);
    }
  };

export const loginManufacturer = (email: string, password: string) => async (dispatch: AuthDispatch) => {

    try {
      localStorage.removeItem("user");
      localStorage.setItem('tempUsername', email.trim());
      localStorage.setItem('authorizationPass', password);
      let dmaDetails = null
      dispatch({ type: LOGIN_MANUFACTURER });
      const cognitoUser = await Auth.signIn({ username: email.trim(), password });

      const user: any = {
        username: cognitoUser.username,
        ...cognitoUser.attributes,
        accessToken: cognitoUser.signInUserSession?.accessToken?.jwtToken,
        idToken: cognitoUser?.signInUserSession?.idToken.jwtToken,
      };

      if (cognitoUser.username && (user && 'custom:group' in user) && user['custom:group'] === 'dma') {
        dmaDetails = await getDMADetails(cognitoUser.username)
        localStorage.setItem('dma', JSON.stringify(dmaDetails));
      }
      

      if(!dmaDetails?.data?.dmaDetail){
        console.log("no dma details");
      }

      if ((user && 'custom:group' in user) && user['custom:group'] === 'dma' && dmaDetails?.data?.dmaDetail?.dmaType !== DmaType.INDIVIDUAL) {
        user.dmaType = dmaDetails?.data?.dmaDetail?.dmaType;
      }

      dispatch({
        type: LOGIN_MANUFACTURER_SUCCESS,
        payload: {
          user,
          challengeName: cognitoUser.challengeName,
          challengeParam: cognitoUser.challengeParam,
        },
      });
        if (cognitoUser && 'challengeName' in cognitoUser && cognitoUser.challengeName.toUpperCase() !== 'NEW_PASSWORD_REQUIRED') {

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('jwt', cognitoUser.signInUserSession?.accessToken?.jwtToken);
            dispatch({
                type: LOGIN_MANUFACTURER_SUCCESS,
                payload: {
                    user,
                    challengeName: cognitoUser.challengeName,
                    challengeParam: cognitoUser.challengeParam,
                },
            });
        } else if (cognitoUser?.attributes && !cognitoUser?.attributes.email_verified) {
        dispatch({type: IS_NOT_VERIFIED});
       await verifyEmail();
      } else {
        dispatch(setCurrentUser(user));
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem(
          "jwt",
          cognitoUser.signInUserSession?.accessToken?.jwtToken
        );
          if(dmaDetails && dmaDetails?.data && (dmaDetails?.data?.dmaDetail['kyc_flow_state'] === 'NOT_UPLOADED' || dmaDetails?.data?.dmaDetail['kyc_flow_state'] === 'REJECTED')){
            window.location.href=('/upload-kyc');
            return;
          }
          if((cognitoUser && cognitoUser.attributes) && cognitoUser.attributes['custom:group']?.toLowerCase() && cognitoUser.attributes['custom:kyc_flow_state']?.toLowerCase()){
            const stockCount  = await fetchDmaStock()

            if(stockCount.result > 0){
              dispatch({type: SET_STOCK_COUNT})
            }else{
              dispatch({type: CLEAR_STOCK_COUNT})
            }
            await dispatch(getCartCount(user.username))
          }

      }
    } catch (error: any) {
      if(error.message.toLowerCase().includes('user is not confirmed.')){
       const data =  await Auth.resendSignUp(email);
       localStorage.setItem('isVerifying', 'true');
       localStorage.setItem('destination', data?.CodeDeliveryDetails?.Destination)
        window.location.href=('/dma-verification');
      }
      dispatch({
        type: LOGIN_MANUFACTURER_ERROR, payload: {error}
      });
    }
  };



export const getCurrentSession = () => async (dispatch: AuthDispatch) => {
  try {
    const userStr = localStorage.getItem("user");
    let user: User;

    if (userStr) {
      user = JSON.parse(userStr);
    } else {
      const cognitoUser = await Auth.currentAuthenticatedUser({bypassCache: true});

      user = {
        username: cognitoUser.username,
        ...cognitoUser.attributes,
        accessToken: cognitoUser.signInUserSession.accessToken.jwtToken,
        idToken: cognitoUser.signInUserSession.idToken.jwtToken,
      };
    }

    localStorage.setItem("user", JSON.stringify(user));

    dispatch({ type: GET_CURRENT_SESSION });
    dispatch(setCurrentUser(user));
  } catch (e) {
    // todo
    dispatch({ type: GET_CURRENT_SESSION_ERROR });
  }
};

export const getNewUserSession = () => async (dispatch: AuthDispatch) => {
  try {
    const session = await Auth.currentSession();
    const user = session.getIdToken().payload;

    localStorage.setItem("user", JSON.stringify(user));
    dispatch(setCurrentUser(user));
  } catch (e) {
    // todo
    console.log(e);
  }
};

export const changeNewManufacturerPassword =
  (oldPassword: string, newPassword: string, username: string) =>
  async (dispatch: AuthDispatch) => {
    try {
      const user = await Auth.signIn(username, oldPassword);
      await Auth.completeNewPassword(user, newPassword);

      dispatch({ type: RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS });
    } catch (e) {
      dispatch({ type: RESET_NEW_MANUFACTURER_PASSWORD_ERROR });
    }
  };

export const logoutUser = () => (dispatch: AuthDispatch) => {
  dispatch({ type: LOGOUT_USER });
};

export const logout = () => async (dispatch: AuthDispatch) => {
  try {
    await Auth.signOut();

    localStorage.clear();

    dispatch({ type: LOGOUT });
  } catch (e) {
    //  todo: an error occurred
    localStorage.clear();
  }
};

export const sendResetPasswordInstruction =
  (email: string) => async (dispatch: AuthDispatch) => {
    try {
      await Auth.forgotPassword(email);
    } catch (e) {}
  };
