import { leftPanelCopies, Views } from '../auth-constants';
import { LeftPanelTypes } from '../../../models';
import { useEffect, useState } from 'react';

type HookDef = (
  initial?: Views,
) => [LeftPanelTypes, (view: Views) => void, Views];
const useLeftPanelCopy: HookDef = (initialView?: Views) => {
  const [view, setView] = useState<Views>(initialView || Views.PersonalInfo);
  const [copy, setCopy] = useState(
    (initialView && leftPanelCopies[initialView]) || leftPanelCopies[view],
  );

  useEffect(() => {
    setCopy(leftPanelCopies[view]);
  }, [view]);

  const changeView = (view: Views) => {
    setView(view);
  };

  return [copy, changeView, view];
};

export default useLeftPanelCopy;
