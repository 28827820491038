import { Select, Table } from "antd";
import React, { useMemo, useState } from "react";
import { getAgrovetListColumns } from "../helpers";
import { Breadcrumb } from "antd";
import { CSVLink } from "react-csv";
import { useEffect } from "react";
import moment, { Moment } from "moment";
import { PageTitle } from "../../../components/PageTitle";
import "../cooperative.css";
import Col from "react-bootstrap/esm/Col";
import { NavLink } from "react-router-dom";
// import { useCollectionCentres } from './useCollectionCentres';
import Swal from "sweetalert2";
import BlockUi from "react-block-ui";
import { useAgrovets } from "./useAgrovets";

const { Option } = Select;

const Agrovets: React.FC = () => {
  // const [tableData, setTableData] = useState<TableData>([]);
  // const [csvData, setCSVData] = useState<TableData>([]);
  const [, setSelectedRow] = useState<Record<string, any>>();
  // const [dateFilter, setDateFilter] = useState<Moment | null>(null);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState("Select Location");

  const { getDMAAgrovets, dmaAgrovets, removeAgrovet, locations, coopAgrovets, setDmaAgrovets, dmaAgrovetsTableData, setDmaAgrovetsTableData } = useAgrovets();

  const columns = useMemo(
    () =>
      getAgrovetListColumns(
        () => {},
        (data) => {
          return Swal.fire({
            title: "Delete Agrovet",
            text: `Are you sure you want to delete this collection Agrovet?`,
            icon: "info",
            showCancelButton: true,
          }).then(async (results: any) => {
            if (results.value) {
              setLoading(true)
              await removeAgrovet(data);
              setLoading(false)
            }
          });
        }
      ),
    []
  );

  // const { getCollectionCentreList, collectionCentres } = useCollectionCentres();

  const mapDataToAgrovets = (data: any) => {
    return data.map((agrovet: any) => {
      return {
        dmaId: agrovet.dmaId,
        businessName: agrovet.businessName,
        ward: agrovet.ward,
        location: agrovet.location,
        dateAdded: moment(agrovet.creationDate).format("MM-DD-YYYY"),
      };
    });
  };

  useEffect(() => {
    (async function getData() {
      await getDMAAgrovets();
      setLoading(false);
    })();
  }, []);

  // useEffect(() => {
  //   if (collectionCentres) {
  //     setTableData(mapDataToCollectionCentres(collectionCentres));
  //   }

  // }, [collectionCentres]);

  const handleOnRow = (row: any) => {
    return {
      onClick: () => setSelectedRow(row),
    };
  };

  const filterLocation = (location: any) => {
    if (location === "Select Location") {
      setDmaAgrovets(coopAgrovets)
    }
    setLocation(location)
    const data = dmaAgrovets.filter((p: any) => p.location === location);
    setDmaAgrovetsTableData(data);
  }

  return (
    <BlockUi blocking={loading}>
      <div className="container pt-5 pb-5 agrovets">
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
          <Breadcrumb.Item>Agrovets</Breadcrumb.Item>
        </Breadcrumb>

        <PageTitle title="Agrovets" >
          <div className="d-flex agrovets-text">
            <div
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
              className="d-flex justify-content-between pr-2 page-title-holder"
            ></div>

            <div className="flex-grow-1">
              <Col
                style={{ marginTop: "2rem", marginBottom: "2rem" }}
                xs="12"
                className="d-flex position-relative"
              >
                <div
                  className="d-flex justify-content-between align-items-center justify-content-center ml-auto position-absolute p-1"
                  style={{
                    right: 0,
                    height: "100%",
                  }}
                >
                  <div
                    className="border-btn white-color rounded d-flex align-items-center justify-content-center text-center"
                    style={{
                      background: "#2cb34a",
                      width: "200px",
                      height: "100%",
                      paddingTop: "1.3rem",
                      paddingBottom: "1.3rem",
                    }}
                  >
                    <NavLink
                      className="white-color fw-bolder text-center no-wrap"
                      to={"/agrovets/add"}
                      style={{
                        fontWeight: "bolder",
                      }}
                    >
                      Add Agrovet
                    </NavLink>
                  </div>
                  {/* <div className="d-flex col-md-4 mb-3">
                    Clear Filters
                  </div> */}
                </div>
              </Col>
            </div>
          </div>
        </PageTitle>
        <div className="d-flex col-md-3 mb-3">
          <Select
            value={location}
            className='agrovets-filter'
            placeholder="Select Location"
            onChange={(location) => filterLocation(location)}
          >
            {locations?.map((p: any) => {
              return (
                <Option value={p}>{p}</Option>
              )
            })}
            
          </Select>
        </div>

        <Table
          id="ant-table"
          className="mt-5 dma-header cooperative-table"
          dataSource={mapDataToAgrovets(dmaAgrovetsTableData)}
          loading={loading}
          columns={columns}
          onRow={handleOnRow}
          scroll={{ x: 768 }}
        />
      </div>
    </BlockUi>
  );
};

export default Agrovets;
