import {useDispatch} from 'react-redux'
import { logout } from '../redux/actions/authActions';

export const LogoutButton = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
      dispatch(logout());
  }

  return (
    <div className="logout" onClick={() => handleLogout()} >
        Logout
    </div>
  );
};
