import { DeleteOutlined, EyeOutlined, MoreOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import { Popover } from 'antd';
import { NavLink } from 'react-router-dom';
import { Alert} from 'react-bootstrap';

export const getBranchListColumns = (
  onMoreClicked: (...args: any[]) => void,
  onDelete?: (data: any) => unknown
) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'branchName',
    render: (_: unknown, data?: any) => {
      return <div>{data.branchName || 'N/A'}</div>;
    },
  },
  {
    title: 'Email',
    dataIndex: 'mail',
    key: 'mail',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Creation Date',
    dataIndex: 'creationDate',
    key: 'creationDate',
  },
  {
    title: 'Branch Type',
    dataIndex: 'isHQ',
    key: 'isHQ',
    render: (_: unknown, data?: any) => {
      return <div>{data.isHQ === 'true' ? 'Main' : 'Sub'}</div>;
    },
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (action: any, data: any) => {
      const content = (
        <NavLink to={`/branches/${data.branchId}/details`}>
          <div style={{ cursor: 'pointer' }} onClick={() => {}}>
            <EyeOutlined /> &nbsp; View More
          </div>
        </NavLink>
      );
      return (
        <Popover placement='leftTop' content={content} trigger='click'>
          <MoreOutlined />
        </Popover>
      );
    },
  },
];

export const getAgrovetListColumns = (nMoreClicked: (...args: any[]) => void,
onDelete?: (data: any) => unknown) => [
  {
    title: 'Agrovet Id',
    dataIndex: 'dmaId',
    key: 'dmaId'
  },
  {
    title: 'Name',
    dataIndex: 'businessName',
    key: 'businessName'
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Ward',
    dataIndex: 'ward',
    key: 'ward',
  },
  {
    title: 'Date Added',
    dataIndex: 'dateAdded',
    key: 'creationDate',
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (action: any, data: any) => {
      const content = (
        <>
        {/* <NavLink to={`/agrovets/${data.dmaId}/details`}>
          <div style={{ cursor: 'pointer' }} onClick={() => {}}>
            <EyeOutlined /> &nbsp; View More
          </div>
        </NavLink> */}
        <div style={{ cursor: 'pointer' }} onClick={() => onDelete && onDelete(data)}>
        <DeleteOutlined /> &nbsp; Delete
      </div>
      </>
      );
      return (
        <Popover placement='leftTop' content={content} trigger='click'>
          <MoreOutlined />
        </Popover>
      );
    },
  },
]

export const getDMAAgentUsersColumns = (
  onMoreClicked: (...args: any[]) => void,
  onDelete?: (data: any) => unknown
) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
];


export const getCollectionCentreListColumns = (
  onMoreClicked: (...args: any[]) => void,
  onDelete?: (data: any) => unknown
) => [
  {
    title: 'Centre ID',
    dataIndex: 'centreId',
    key: 'centreId',
    render: (_: unknown, data?: any) => {
      return <div>{data.centreId || 'N/A'}</div>;
    },
  },
  {
    title: 'Centre Name',
    dataIndex: 'centreName',
    key: 'centreName',
    render: (_: unknown, data?: any) => {
      return <div>{data.centreName || 'N/A'}</div>;
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Date Added',
    dataIndex: 'dateAdded',
    key: 'dateAdded',
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (action: any, data: any) => {
      const content = (
        <NavLink to={`/collection-centres/${data.collectionCentreId}/details`}>
          <div style={{ cursor: 'pointer' }} onClick={() => {}}>
            <EyeOutlined /> &nbsp; View More
          </div>
        </NavLink>
      );
      return (
        <Popover placement='leftTop' content={content} trigger='click'>
          <MoreOutlined />
        </Popover>
      );
    },
  },
];

export const getTransporterListColumns = (
  onMoreClicked: (...args: any[]) => void,
  onActivate?: (data: any) => unknown,
  onDeactivate?: (data: any) => unknown
) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (_: unknown, data?: any) => {
      return <div>{data.id || 'N/A'}</div>;
    },
  },
  {
    title: 'Transporter Name',
    dataIndex: 'transporterName',
    key: 'transporterName',
    render: (_: unknown, data?: any) => {
      return <div>{data.transporterName || 'N/A'}</div>;
    },
  },
  {
    title: 'ID Number',
    dataIndex: 'idNumber',
    key: 'idNumber',
  },
  {
    title: 'Email Address',
    dataIndex: 'emailAddress',
    key: 'emailAddress',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: 'Route',
    dataIndex: 'route',
    key: 'route',
  },
  {
    title: 'Status',
    dataIndex: 'enabled',
    key: 'enabled',
    render: (_: unknown, data?: any) => {
      const Content = (
      (data?.enabled === 'true') ?
        <span className='activated'>ACTIVATED</span>
      :
        <span className='deactivated'>DEACTIVATED</span>
      )
      return Content;
    }
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (action: any, data: any) => {
      const content = (
        <>
        <div>
          <NavLink to={`/transporters/${data.id}/details`}>
            <div style={{ cursor: 'pointer' }} className="mb-3" onClick={() => {}}>
              <EyeOutlined /> &nbsp; View More
            </div>
          </NavLink>
        </div>
        {data?.enabled === 'true' ?         
          <div style={{ cursor: 'pointer' }} onClick={() => onDeactivate && onDeactivate(data)}>
            <CloseOutlined /> &nbsp; Deactivate
          </div>
        : 
          <div style={{ cursor: 'pointer' }}onClick={() => onActivate && onActivate(data)}>
            <CheckOutlined /> &nbsp; Activate
          </div>
        }
        </>
      );
      return (
        <Popover placement='leftTop' content={content} trigger='click'>
          <MoreOutlined />
        </Popover>
      );
    },
  },
];

export const getDeliveryApprovalColumns = (
  onMoreClicked: (...args: any[]) => void,
  handleShowDetails: (data: any) => void,
  onDelete?: (data: any) => unknown
) => [
  {
    title: 'Transporter Name',
    dataIndex: 'transporterName',
    key: 'transporterName',
    render: (_: unknown, data?: any) => {
      return <div>{data.transporterName || 'N/A'}</div>;
    },
  },
  {
    title: 'Collection Center',
    dataIndex: 'collectionCenterName',
    key: 'collectionCenterName',
  },
  {
    title: 'Total Quantity',
    dataIndex: 'totalQuantity',
    key: 'totalQuantity',
  },
  {
    title: 'Approved Quantity',
    dataIndex: 'approvedQuantity',
    key: 'approvedQuantity',
  },
  {
    title: 'Rejected Quantity',
    dataIndex: 'rejectedQuantity',
    key: 'rejectedQuantity',
  },
  {
    title: 'Variance Quantity',
    dataIndex: 'varianceQuantity',
    key: 'varianceQuantity',
  },
  {
    title: 'Approval Name',
    dataIndex: 'approvalUserName',
    key: 'approvalUserName',
  },
  {
    title: 'Approval Date',
    dataIndex: 'approvalDate',
    key: 'approvalDate',
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (action: any, data: any) => {
      const content = (
        // <NavLink to={`/transporters/${data.id}/details`}>
          <div style={{ cursor: 'pointer' }} onClick={() => {handleShowDetails(data)}}>
            <EyeOutlined /> &nbsp; View More
          </div>
        // </NavLink>
      );
      return (
        <Popover placement='leftTop' content={content}>
          <MoreOutlined />
        </Popover>
      );
    },
  },
];