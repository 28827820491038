import React, { FC, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import ErrorBoundary from '../components/ErrorBoundary';
import { Header } from './Header';
import { AuthRouteProps } from './layouts.model';
import { getCurrentSession } from '../redux/actions/authActions';
import { Col, Row } from 'react-bootstrap';
import { SideNav } from './SideNav';
import {useHistory} from "react-router-dom"

export const ManufacturerRoute: FC<AuthRouteProps> = ({
  component: Component,
  auth,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [hasCheckedSession, setHasCheckedSession] = useState(false);

  const history = useHistory()
  useEffect(() => {
    if (!hasCheckedSession) {
      dispatch(getCurrentSession());
      // setHasCheckedSession(true);
    }
  }, [hasCheckedSession, dispatch]);

  const isManufacturer = (auth: any) => {
    if (auth.isAuthenticated && auth?.user) {
      return auth.user["custom:group"]?.toLowerCase() === "manufacturer";
    }
    return false;
  };


  return (
    <Route
      {...rest}
      render={(props) => {
        const { match: { url }, } = props;
        if (isManufacturer(auth)) {
          if (url === '/manufacturers' || url === '/manufacturers/') {
            return <Redirect to="/manufacturers/dashboard" />;
          }

          return (
            <ErrorBoundary>
              <main
                className="container-fluid m-0 p-0 page-bg"
                id="private-route-layout"
              >
                <Row className="m-0 p-">
                  <SideNav />
                  <Col className="m-0 p-0 main-page">
                    <Header />
                    <Col className="p-3">
                      {Component && <Component {...{...props, user: auth.user}} />}
                    </Col>
                  </Col>
                </Row>
              </main>
            </ErrorBoundary>
          );
        } else {
          //enable this line below to make route protected
         history.push('/login')
        }
      }}
    />
  );
};

const mapStateToProps = ({ auth }: AuthRouteProps) => ({
  auth,
});

export default connect(mapStateToProps)(ManufacturerRoute);
