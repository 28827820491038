import './IDProductUploadInfo.css';

import React, { FC } from 'react';
import { Table } from 'react-bootstrap';

import { Product } from '../../API';
import { capitalizeFirstLetter } from '../../pages/sales/helpers';
import { formatAmountWithCurrency } from '../../utills/common';

interface IDProductUploadInfoProps {
    info: Omit<Product, 'image'>;
}

export const IDProductUploadInfo: FC<IDProductUploadInfoProps> = ({ info }) => {
    return (
        <div className="id-product-info">
            <div className={`status ${info.status}`}>{capitalizeFirstLetter(info.status) || 'In Stock'}</div>
            <h1 className="mt-4 title">{info.name}</h1>
            <p className="manufacturerName">{info.company_name}</p>
            <div className="mt-2 description">{info.description}</div>
            <div className="mt-2">
                <Table borderless>
                    <tbody>
                        <tr>
                            <td>Input Type</td>
                            <td>{info.type}</td>
                        </tr>
                        <tr>
                            <td>Input Sub-Category</td>
                            <td>{info?.subCategoryName}</td>
                        </tr>
                        <tr>
                            <td>Unit Quantity</td>
                            <td>{info.unit}</td>
                        </tr>
                        <tr>
                            <td>Quantity</td>
                            <td>{info.quantity}</td>
                        </tr>
                        <tr>
                            <td>DMA Price</td>
                            <td>{formatAmountWithCurrency(info.dmaPrice || 0)}</td>
                        </tr>
                        <tr>
                            <td>Rebates</td>
                            <td>{`${info?.rebates || 0} KSH`}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div className="w-100 price">Ksh {info.price as number}</div>
        </div>
    );
};
