import { DmaType } from "../API";

// export const isDMA = (auth: any) => {
//   if (auth?.user) {
//     return auth.user["custom:group"]?.toLowerCase() === "dma";
//   }
//   return false;
// };

export const isCooperativeMulti = () => {
    const details = JSON.parse(localStorage.getItem('dma') || '{}');
    if (
      details?.data?.dmaDetail?.dmaType === DmaType.COOPERATIVE ||
      details?.data?.dmaDetail?.dmaType === DmaType.MULTI
    ) {
      return true;
    }
    return false;
};

export const isCooperative = () => {
  const details = JSON.parse(localStorage.getItem('dma') || '{}');
  if (
    details?.data?.dmaDetail?.dmaType === DmaType.COOPERATIVE
  ) {
    return true;
  }
  return false;
}

export const isIndividual = () => {
  const details = JSON.parse(localStorage.getItem('dma') || '{}');
  if (
    details?.data?.dmaDetail?.dmaType === DmaType.INDIVIDUAL
  ) {
    return true;
  }
  return false;
}

export const isCollectionCentreUser = () => {
  const details = JSON.parse(localStorage.getItem('user') || '{}')
  if (details['custom:collectionCentreId']) {
    return true;
  }
  return false;
};

export const isMainBranchUser = () => {
    const details = JSON.parse(localStorage.getItem('dma') || '{}');
    if (details?.data?.dmaBranchDetail[0].isHQ === "true") {
      return true;
    }
    return false;
}

export const isManufacturer = (auth: any) => {
  if (auth?.user) {
    return auth.user["custom:group"]?.toLowerCase() === "manufacturer";
  }
  return false;
};

export const isApprovedOrPendingDMA = (auth: any) => {
  const dmaObject = JSON.parse(localStorage.getItem('dma') || '{}');

  const userDetail = dmaObject?.data?.dmaDetail;

  if (userDetail) {
    const kycFlowState = userDetail['kyc_flow_state'];

    if (kycFlowState === 'APPROVAL_PENDING' || kycFlowState === 'APPROVED') {
      return true;
    }
    return false;
  }
  return false;
};

export const isApprovedDMA = (auth: any) => {
  const dmaObject = JSON.parse(localStorage.getItem('dma') || '{}');

  const userDetail = dmaObject?.data?.dmaDetail;

  if (userDetail) {
    const kycFlowState = userDetail['kyc_flow_state'];

    if (kycFlowState === 'APPROVED') {
      return true;
    }
    return false;
  }
  return false;
};

export const isDMA = (auth: any) => {
  const dmaObject = JSON.parse(localStorage.getItem('dma') || '{}');

  const userDetail = dmaObject?.data?.dmaDetail;

  if (userDetail && (userDetail.dmaType === DmaType.INDIVIDUAL || 
    userDetail.dmaType === DmaType.COOPERATIVE || userDetail.dmaType === DmaType.MULTI)) {
      return true;
    }
    return false;
};

export const kycUploaded = (auth: any) => {
  
  const dmaObject = JSON.parse(localStorage.getItem('dma') || '{}');

  const dmaDetail = dmaObject?.data?.dmaDetail;

  if (dmaDetail) {
    const kycFlowState = dmaDetail['kyc_flow_state'];
    if (kycFlowState === 'APPROVAL_PENDING' || kycFlowState === 'APPROVED') {
      return true;
    }
    return false;
  }
  return false;
  
};


