import {  EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { NavLink } from 'react-router-dom';
import { NestedOrders } from '../../API';

export const capitalizeFirstLetter = (string: string | null | undefined) => {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getColumns = (
  onMoreClicked: (...args: any[]) => void,
  onApprove?: (data: any) => unknown,
  onReject? : (data: any) => unknown
) => [
  {
    title: 'OrderID',
    dataIndex: 'orderId',
    key: 'orderId'
  },
  {
    title: 'DMA Name',
    dataIndex: 'dmaName',
    key: 'dmaName',
  },
  {
    title: 'Hub Name',
    dataIndex: 'hubName',
    key: 'hubName'
  },
  {
    title: 'Date of Order',
    dataIndex: 'creationDate',
    key: 'creationDate',
  },
  {
    title: 'Location',
    dataIndex: 'dmaLocation',
    key: 'dmaLocation',
  },

  {
    title: 'Total Price',
    dataIndex: 'price',
    key: 'price',
    render: (_: unknown, data?: any) => {
      const total = data?.orderItems?.map((a: any) => a.total).reduce((a: number, b: number) => a + b);
      return <div>{total}</div>; 
    },
  },
  {
    title: 'Payment Method',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
  },
 
  {
    title: 'Status',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    render: (_: unknown, data?: any) => {
      return <div className={data.orderStatus}>{capitalizeFirstLetter(data.orderStatus)}</div>; 
    },
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (action: any, data: any) => {
      const content = (
        <>
          <div>
            <NavLink style={{ color: 'rgba(0, 0, 0, 0.85)' }} to={`/manufacturers/sales/${data.orderId}`}>
              <EyeOutlined /> &nbsp; View More
            </NavLink>
          </div>
        </>
      );
      return (
        <Popover placement="leftTop" content={content} trigger="click">
          <MoreOutlined />
        </Popover>
      );
    },
  },
];

export const calculateTotalPrice = (order: NestedOrders) => {
 let total = 0

 if (!order) {
   return 0;
 }

 order?.orderItems?.forEach((p) => {
  total += p?.total || 0
 })

 return total
}

export const mapSalesToTableData = (
  sales: (NestedOrders | null | undefined)[] | null | undefined
) =>
  sales?.map((sale) => {
    return {
      orderId: sale?.orderId,
      dmaName: sale?.dmaName,
      dmaLocation: sale?.dmaLocation,
      creationDate: new Date(sale?.creationDate as string).toLocaleString(),
      paymentStatus: sale?.paymentStatus,
      paymentMethod: sale?.paymentMethod,
      orderStatus: sale?.orderStatus,
      dateAdded: sale?.creationDate,
      orderItems: sale?.orderItems,
      hubName: sale?.hub?.hubName || 'N/A'
    };
  });
