import '../products.css';

import { Breadcrumb } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';

import { Search } from '../../../../components/Search';
import { logout } from '../../../../redux/actions/authActions';

import { PageTitle } from '../../../../components/PageTitle';

import { useProducts } from '../useProducts';
import ProductBulkUpload from '../ProductBulkUpload';
import ProductUploadList from '../ProductUploadList';
import ProductUploadDetails from '../ProductUploadDetails';
import EditUploadProduct from '../EditUploadProduct';
import { Auth } from 'aws-amplify';
import { useHubs } from '../../hubs/useHubs';

type Products = {
  productId: string | undefined;
  company_name: string | undefined | null;
  type: number | string | null | undefined;
  name: string | null | undefined;
  quantity: number | string | null | undefined;
  category: number | string | null | undefined;
  price: number | string | undefined;
  dateAdded: string | null | undefined;
  status: string | null | undefined;
  unit: number | null | undefined;
  creationDate: string | null | undefined;
};


type UploadedProducts = {
  code: Number;
  status: String;
  failedToBeAddedToDynamoProducts: any[];
  validProducts: Products[];
  invalidProducts: Products[];
  identifiedDuplicateProducts: Products[];
};

const ProductsUpload: React.FC<{user: any}> = (props) => {
  const { user } = props;
  const [key, setKey] = useState('upload');
  const [uploadTabKey, setUploadTabKey] = useState('valid');
  const [searchTerm, setSearchTerm] = useState('');
  // const [manufacturer, setManufacturer] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [view, setView] = useState('uploadList');
  const [productDetailsId, setProductDetailsId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [productUploadData, setProductUploadData] = useState<UploadedProducts>({
    code: 200,
    status: '',
    failedToBeAddedToDynamoProducts: [],
    validProducts: [],
    invalidProducts: [],
    identifiedDuplicateProducts: [],
  });
  const manufacturerId =  user['custom:manufacturerId'];
  const companyName =  user['custom:company_name'];

  const [preserveUploadData, setPreserveProductUploadData] =
    useState<UploadedProducts>({
      code: 200,
      status: '',
      failedToBeAddedToDynamoProducts: [],
      validProducts: [],
      invalidProducts: [],
      identifiedDuplicateProducts: [],
    });

  const [hubId, setHubId] = useState('');

  const dispatch = useDispatch();

  const { removeProduct } = useProducts();
  const { fetchManufacturerHubs, hubsTableData } = useHubs();

  const handleLogout = () => {
    dispatch(logout());
  };


  const readFileDataAsBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target?.result) resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsText(file);
    });
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        return handleLogout();
      }
    }
  );

  const updateEditedUploadProduct = async (
    productDetailsId: string,
    product: any
  ) => {
    const products = _.cloneDeep(productUploadData.validProducts);
    const foundIndex = products.findIndex(
      (p) => p.productId === productDetailsId
    );
    products[foundIndex] = product;
    setProductUploadData({
      code: productUploadData.code,
      status: productUploadData.status,
      failedToBeAddedToDynamoProducts:
        productUploadData.failedToBeAddedToDynamoProducts,
      validProducts: products,
      invalidProducts: productUploadData.invalidProducts,
      identifiedDuplicateProducts:
        productUploadData.identifiedDuplicateProducts,
    });
    return;
  };

  useEffect(() => {
    if (!!manufacturerId && hubsTableData?.length == 0) {
      fetchManufacturerHubs(manufacturerId);
    }

    if (!searchTerm) {
      setProductUploadData({
        code: preserveUploadData.code,
        status: preserveUploadData.status,
        failedToBeAddedToDynamoProducts:
          preserveUploadData.failedToBeAddedToDynamoProducts,
        validProducts: preserveUploadData.validProducts,
        invalidProducts: preserveUploadData.invalidProducts,
        identifiedDuplicateProducts:
          preserveUploadData.identifiedDuplicateProducts,
      });
      return;
    }
    if (uploadTabKey === 'valid') {
      const products = preserveUploadData.validProducts.filter(
        (p) =>
          p.company_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          p.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setProductUploadData({
        code: productUploadData.code,
        status: productUploadData.status,
        failedToBeAddedToDynamoProducts:
          productUploadData.failedToBeAddedToDynamoProducts,
        validProducts: products,
        invalidProducts: productUploadData.invalidProducts,
        identifiedDuplicateProducts:
          productUploadData.identifiedDuplicateProducts,
      });
    }

    if (uploadTabKey === 'invalid') {
      const invalidProducts = preserveUploadData.invalidProducts.filter(
        (p) =>
          p.company_name?.toLowerCase().includes(searchTerm) ||
          p.name?.toLowerCase().includes(searchTerm)
      );
      setProductUploadData({
        code: productUploadData.code,
        status: productUploadData.status,
        failedToBeAddedToDynamoProducts:
          productUploadData.failedToBeAddedToDynamoProducts,
        validProducts: productUploadData.validProducts,
        invalidProducts: invalidProducts,
        identifiedDuplicateProducts:
          productUploadData.identifiedDuplicateProducts,
      });
    }

    if (uploadTabKey === 'duplicate') {
      const duplicateProducts =
        preserveUploadData.identifiedDuplicateProducts.filter(
          (p) =>
            p.company_name?.toLowerCase().includes(searchTerm) ||
            p.name?.toLowerCase().includes(searchTerm)
        );
      setProductUploadData({
        code: productUploadData.code,
        status: productUploadData.status,
        failedToBeAddedToDynamoProducts:
          productUploadData.failedToBeAddedToDynamoProducts,
        validProducts: productUploadData.validProducts,
        invalidProducts: productUploadData.invalidProducts,
        identifiedDuplicateProducts: duplicateProducts,
      });
    }
    
  }, [searchTerm]);

  const handleHubIdChange = (e: any) => {
    setHubId(e?.target?.value);
  }

  const uploadProducts = async () => {
    try {
      if (!!hubId && files.length > 0) {
        setLoading(true);

        const fileContents = await readFileDataAsBase64(files[0]);
        const currentUserSession = (await Auth.currentSession());
        const jwt = currentUserSession.getAccessToken().getJwtToken();
        const { payload: { 'custom:company_name': companyName, 'custom:manufacturerId': manufacturerId } } = currentUserSession.getIdToken();
   
        const queryParams = `manufacturerId=${manufacturerId}&hubId=${hubId}&companyName=${companyName}`
        const config: AxiosRequestConfig = {
          method: 'post',
          url: `${process.env.REACT_APP_BULK_PRODUCTS_UPLOAD_URL}/v1/bulk-upload-product?${queryParams}`,
          headers: {
            'Content-Type': files[0].type,
            Authorization: `Bearer ${
              jwt
            }`,
          },
          data: fileContents,
        };
        const res = await axios(config);
        const apiResponse = await res;
        if (apiResponse && 'code' in apiResponse?.data) {
          const data = apiResponse?.data;
          setProductUploadData(data);
          setPreserveProductUploadData(data);
          setKey('uploadList');
        } else {
          return toast.error(apiResponse?.data.message);
        }
      } else {
        return toast.error('Please upload a file and select a manufacturer');
      }
    } catch (e: any) {
      console.log('ERROR: ', e);
      return toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteProduct = (id: string) => {
    return Swal.fire({
      title: 'Delete Product',
      text: `Are you sure you want to delete this product?`,
      confirmButtonColor: '#f01d1d',
      icon: 'error',
      showCancelButton: true,
    }).then(async (results: any) => {
      if (results.value) {
        await removeProduct(id as string);
        const getValidProducts = preserveUploadData?.validProducts.filter(
          (p) => p.productId !== id
        );
        setProductUploadData({
          ...preserveUploadData,
          validProducts: getValidProducts as Products[],
        });
        setView('uploadList');
      }
    });
  };

  return (
    <BlockUi blocking={loading}>
      <ToastContainer />
      <div className='content-wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item href='/home'>Home</Breadcrumb.Item>
                <Breadcrumb.Item href='/products'>Products</Breadcrumb.Item>
                <Breadcrumb.Item href=''>Products Upload</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className='row mt-4 mb-3'>
            <PageTitle title='Products Upload' />
          </div>
          <div className='row'>
            <div className='row mt-4 mb-5'>
              <div className='col-md-4'>
                <Search
                  iconColor={'#d7d7d7'}
                  placeholder='Search'
                  value={searchTerm}
                  onChange={(value) => setSearchTerm(value)}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <div className='manufacturer'>
                <Tabs
                  id='controlled-tab-example'
                  activeKey={key}
                  onSelect={(k: any) => setKey(k)}
                >
                  <Tab
                    eventKey='upload'
                    tabClassName=' uppercase'
                    title='PRODUCTS UPLOAD'
                  >
                    <ProductBulkUpload
                      files={files}
                      setFiles={setFiles}
                      uploadProducts={uploadProducts}
                      manufacturerHubs={hubsTableData}
                      hubId={hubId}
                      handleHubIdChange={handleHubIdChange}
                    />
                  </Tab>
                  <Tab
                    eventKey='uploadList'
                    disabled={key === 'upload'}
                    tabClassName='uppercase'
                    title='PRODUCTS UPLOAD LIST'
                  >
                    {view === 'uploadList' && (
                      <ProductUploadList
                        productUploadData={
                          productUploadData as UploadedProducts
                        }
                        setView={setView}
                        productDetailsId={productDetailsId as string}
                        setProductDetailsId={setProductDetailsId}
                        deleteProduct={deleteProduct}
                        uploadTabKey={uploadTabKey}
                        setUploadTabKey={setUploadTabKey}
                      />
                    )}
                    {view === 'uploadDetails' && (
                      <ProductUploadDetails
                        setView={setView}
                        productDetailsId={productDetailsId}
                        deleteProduct={deleteProduct}
                      />
                    )}
                    {view === 'uploadEdit' && (
                      <EditUploadProduct
                        setView={setView}
                        productDetailsId={productDetailsId}
                        updateEditedUploadProduct={updateEditedUploadProduct}
                      />
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockUi>
  );
};

export default ProductsUpload;
