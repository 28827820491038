import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import {Breadcrumb, Table} from "antd";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getDmastockUpdateHistoryById } from "../../../graphql/queries";
import { GetDmastockUpdateHistoryByIdQuery } from "../../../API";
import { GraphQLResult } from "@aws-amplify/api";
import {addCognitoAuth} from "../../../helpers/helpers";


type DmaStockUpdateHistoryType = {
    match: {
        params: {
            stockId: string;
        };
    };
};

export const csvHeaders = [
    { label: 'Date', key: 'modificationDate' },
    { label: 'Product Name', key: 'name' },
    { label: 'Product Type', key: 'unit' },
    { label: 'Unit Price(Ksh)', key: 'sellingPrice' },
    { label: 'Stock', key: 'quantity' },
    { label: 'Status', key: 'status' },
    { label: 'Activity Type', key: 'activity' },
];

const getColumns = [
    {
        title: 'Date',
        dataIndex: 'modificationDate',
        key: 'modificationDate',
    },
    {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Product Type',
        dataIndex: 'unit',
        key: 'unit',
    },
    {
        title: 'Unit Price(Ksh)',
        dataIndex: 'sellingPrice',
        key: 'sellingPrice',
    },
    {
        title: 'Stock',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Activity Type',
        dataIndex: 'activity',
        key: 'activity',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, data: any) => {
            return (
                <div
                    style={{ color: 'white', width: 'auto', fontSize: '14px' }}
                    className={`${
                        data.status && data.status.toLowerCase() === 'approved' 
                            ? 'in__stock' : data.status && data.status.toLowerCase() === 'pending' 
                                ? 'ax__pending' : ''
                    }`}
                >
                    {data.status}
                </div>
            );
        },
    },
];

const mapResponseToHistoryData = (data: any) => {
    return (
        data?.updateHistory.map((hist: any, index: any) => ({
            key: hist.modificationDate,
            name: hist.newVersion.name,
            quantity: hist.newVersion.quantity,
            unit: hist.newVersion.unit,
            sellingPrice: hist.newVersion.selling_price,
            status: hist.newVersion.bulkUploadProcessStatus,
            activity: 'Update',
            modificationDate: hist.modificationDate.split('T')[0],
            children: [
                {
                    key: hist.oldVersion?.name || '' + index,
                    name: hist.oldVersion?.name,
                    quantity: hist.oldVersion?.quantity,
                    sellingPrice: hist.oldVersion?.selling_price,
                    unit: hist.oldVersion?.unit,
                    status: '',
                    activity: hist.oldVersion?.name ? 'Original' : '',
                },
            ],
        })) || []
    );
};

const mapRespToCsvHistoryData = (data: any) => {
    return (
        data?.updateHistory.reduce((prev: any, hist: any) => {
            prev.push({
                modificationDate: hist.modificationDate.split('T')[0],
                name: hist.newVersion.name,
                quantity: hist.newVersion.quantity,
                unit: hist.newVersion.unit,
                sellingPrice: hist.newVersion.selling_price,
                status: hist.newVersion.bulkUploadProcessStatus,
                activity: 'Update',
            });
            prev.push({
                name: hist.oldVersion?.name,
                quantity: hist.oldVersion?.quantity,
                unit: hist.oldVersion?.unit,
                sellingPrice: hist.oldVersion?.selling_price,
                status: '',
                activity: hist.oldVersion?.name ? 'Original' : '',
            });
            return prev;
        }, []) || []
    );
};

const DmaStockUpdateHistory: React.FC<DmaStockUpdateHistoryType> = (props) => {
    const stockId = props.match.params.stockId;
    const [historyData, setHistoryData] = useState([]);
    const [csvHistoryData, setCsvHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDmaStockUpdateHistory = async () => {
        const query = graphqlOperation(getDmastockUpdateHistoryById, { dmastockId: stockId });
        try {
            const resp = (await API.graphql(addCognitoAuth(query))) as GraphQLResult<GetDmastockUpdateHistoryByIdQuery>;
            setLoading(false);
            setHistoryData(mapResponseToHistoryData(resp?.data?.getDmastockUpdateHistoryById || {}));
            setCsvHistoryData(mapRespToCsvHistoryData(resp?.data?.getDmastockUpdateHistoryById || {}));
        } catch (e) {
            setLoading(false);
        }
    };
    useEffect(() => {
        setLoading(true);
        getDmaStockUpdateHistory();
    }, []);

    return (
        <div className="container p-5">
            <Breadcrumb separator=">">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href={`/dma/stock`}>View Stock</Breadcrumb.Item>
                <Breadcrumb.Item href="">Stock Update history</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row mt-3 ">
                <div className="d-flex justify-content-between fullwidth">
                    <div className="fs-20 primary-color fw-500">Stock Update History</div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="d-flex justify-content-end">
                    <div className="p-2 bd-highlight">
                        {' '}
                        <CSVLink data={csvHistoryData} headers={csvHeaders} filename="stock-history">
                            <div className="border-btn text-center d-flex align-items-center justify-content-around">
                                Export Report <DownloadOutlined style={{ color: '#2cb34a' }} />
                            </div>
                        </CSVLink>
                    </div>
                </div>
            </div>
            <Table
                className={'update-header'}
                loading={loading}
                dataSource={historyData}
                columns={getColumns}
                pagination={{
                    total: historyData.length,
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    disabled: false,
                }}
            />
        </div>
    );
};

export default DmaStockUpdateHistory;
