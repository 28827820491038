import { FC } from "react";
import { Route, useHistory} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import ErrorBoundary from "../components/ErrorBoundary";
import DmaHeader  from "../pages/DMA/components/DmaHeader";
import { Footer } from "./Footer";
import { AuthRouteProps } from './layouts.model';
import { logout } from "../redux/actions/authActions";

export const DmaPublicRoute: FC<AuthRouteProps> = ({
  component: Component,
  auth,
  ...rest
}) => {

  const dispatch = useDispatch()
  const history = useHistory();

  const dmaObject = JSON.parse(localStorage.getItem('dma') || '{}');

  const userDetail = dmaObject?.data?.userDetail;

  const kycFlowState = userDetail ?  userDetail['kyc_flow_state'] : undefined;

  const authPass = auth?.isAuthenticated
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authPass) {
          return (
            <ErrorBoundary>
              <DmaHeader />
              <main
                className='dma-bg dma-page-margin page-pagging-bottom min-height-100'
                id='public-route-layout'
              >
                {Component && <Component {...props} />}
              </main>
              <Footer />
            </ErrorBoundary>
          );
        } else if (authPass && (kycFlowState === 'APPROVED' || kycFlowState === 'APPROVAL_PENDING')) {
          history.push('/upload-kyc');
        } else {
          dispatch(logout());
          history.push('/login');
        }
        
      }}
    />
  );
};

const mapStateToProps = ({ auth }: AuthRouteProps) => ({
  auth,
});

export default connect(mapStateToProps)(DmaPublicRoute);
