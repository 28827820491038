import '../cooperative.css';

import { Breadcrumb, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import BlockUi from 'react-block-ui';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../../../components/PageTitle';
import { useAgrovets } from './useAgrovets';
import { toast, ToastContainer } from 'react-toastify';


const { Option } = Select

const AddAgrovet: React.FC = () => {


  /**
   * @description Hooks
   */

  const [isLoading, setIsLoading] = useState(true)
  const [selectedAgrovet, setSelectedAgrovet] = useState("")

  const { addAgrovet, getAgrovets, agrovets } = useAgrovets()

  useEffect(() => {
    (async function getDate() {
      await getAgrovets()
      setIsLoading(false)
    })()
  }, [])

  const createAgrovet = async () => {
    if (!selectedAgrovet) {
      return toast.error('Kindly select an agrovet')
    }
    setIsLoading(true)
    const data = await addAgrovet(selectedAgrovet)
    if (data.code === 200) {
      history.push('/agrovets')
    } else {
      return toast.error(data.message)
    }
    
  }

 
  const history = useHistory();

  return (
    <div className='container'>
      <ToastContainer/>
      <div className='row'>
        <div className='col-md-12'>
          <BlockUi blocking={isLoading}>
            <ToastContainer />
            <div style={{ marginTop: '2rem' }}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item href='/dashboard'>Home</Breadcrumb.Item>
                <Breadcrumb.Item href='/agrovets'>
                  Agrovets
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Agrovet</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <PageTitle title='Add Agrovet' />

            <div>
              <Card className='addBranch-cardbody shadow-lg p-3 mb-5'>
                <Card.Body>
                  <h4 style={{ fontWeight: 'bolder' }} className='text-center'>ADD/LINK AGROVET</h4>
                  <Form>
                    <div className='d-flex'> 
                      <Col md={12} xs={{ span: 12 }} className='p-3'>
                        <h5 style={{ marginBottom: '15px', fontWeight: 'bolder' }}>Agrovet Details</h5>
                        <br/>
                        <div className='form-group'>
                          <label className='auth-label mb-2'>Agrovet Name</label>
                          <Select
                            showSearch
                            className='agrovet-details-select'
                            placeholder='Top Line Agrovet'
                            optionFilterProp='children'
                            value={selectedAgrovet}
                            onChange={setSelectedAgrovet}
                            // onSelect={(e) => setSelectedAgrovet(e.target.value)}
                            
                          >
                            {agrovets && agrovets.map((agrovet: any) => {
                              return (
                                <Option key={agrovet.dmaId} value={agrovet.dmaId}>
                                  {agrovet.businessName}
                                </Option>
                              )
                            })}
                            {/* {/* <Option value="male">
                              Select your gender
                            </Option> */}
                             {/* <Option value="male">
                              Male
                            </Option>
                            <Option value="female">
                              Female
                            </Option>  */}
                          </Select> 
                        </div>
                      </Col>
                    </div>
                    <div className='d-flex flex-row-reverse justify-content-flex-end fullwidth mt-5'>
                      <button
                        onClick={(e) => {e.preventDefault(); createAgrovet()}}
                        className='add-branch-btn primary-btn fw-500 m-3 btn-width'
                        disabled={isLoading}
                      >
                        {isLoading ? 'LOADING...' : 'ADD AGROVET'}
                      </button>
                      <button
                        className='cancel-btn fw-500 m-3 btn-width pointer'
                        onClick={() => history.push('/agrovets')}
                      >
                        CANCEL
                      </button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default AddAgrovet;
