import React from 'react';
import { Col, Form } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

import { ProductUpload } from '../components/ProductUpload';
import awsConfig from "../../../aws-config";
import { Hub } from '../../../API';
import { ProductHubSelect } from '../components/ProductHubSelect';
import { HubModel } from '../hubs/useHubs';

type UploadInformationProps = {
  setFiles: (files: File[]) => void;
  files: File[];
  uploadProducts: () => void;
  manufacturerHubs: HubModel[];
  hubId: string
  handleHubIdChange: (e: any) => void;
};

const ProductBulkUpload: React.FC<UploadInformationProps> = ({
  files,
  setFiles,
  uploadProducts,
  hubId,
  handleHubIdChange,
  manufacturerHubs
}) => {
  return (
    <div className='mt-5'>
      <Card className='roles-cardbody shadow-lg p-3 mb-5'>
        <Card.Body>
        <Col
            md={6}
            className=''
            style={{
              marginBottom: '15px',
              width: '86%',
            }} 
          >
            <Form.Label className='new-product-form-label'>
              <span
                style={{
                  color: 'black',
                  float: 'left',
                  fontSize: '15px',
                  fontWeight: 'bolder',
                }}
              >
                Upload Documents
              </span>
            </Form.Label>
            <ProductHubSelect
              label='HUB NAME'
              value={hubId || ''}
              required
              data={manufacturerHubs}
              name='hubId'
              className='shadow-none'
              onChange={handleHubIdChange}
            />
            
          </Col>
          <Col
            md={6}
            className=''
            style={{
              marginBottom: '15px',
              width: '86%',
            }} 
          >
            <div style={{fontSize: '15px'}}>
                The Template can be downloaded from
                <a
                    style={{color: '#2cb34a'}}
                    target="_blank"
                    rel="noreferrer"
                    href={`${awsConfig.Storage.asset}/test-bulk-upload-products.csv`}
                >
                    &nbsp;here
                </a>
            </div>
          </Col>

          <Col
            md={6}
            className=''
            style={{ marginBottom: '15px', marginLeft: '%', width: '90%' }}
          >
            <ProductUpload
              files={files}
              setFiles={setFiles}
              handleUpload={uploadProducts}
            />
          </Col>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductBulkUpload;
