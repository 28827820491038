import { ChangeEvent, FC } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { ProductInput } from '../../../API';

const labelStyle = {
  fontSize: '0.85rem',
  fontWeight: 600,
};

const textAreaStyle = {
  height: 200,
};

interface ProductFieldProps {
  type?: 'text' | 'number' | 'textarea';
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
  name: keyof ProductInput;
}

export const ProductField: FC<ProductFieldProps> = (props) => {
  return (
    <Form.Group>
      <Form.Label className="auth-label mb-2" style={labelStyle}>
        {props.label}
      </Form.Label>
      {props.type !== 'textarea' ? (
        <Form.Control {...props} type={props.type || 'text'} className="mb-3" />
      ) : (
        <Form.Control
          {...props}
          as="textarea"
          style={textAreaStyle}
          className="mb-3"
        />
      )}
    </Form.Group>
  );
};
