export const IS_LOGGING_IN = 'IS_LOGGING_IN';
export const DONE_LOGGING_IN = 'DONE_LOGGING_IN';
export const REGISTERING = 'REGISTERING';
export const DONE_REGISTERING = 'DONE_REGISTERING';
export const SET_USER = 'SET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const LOGIN_MANUFACTURER = '[AUTH] LOGIN_MANUFACTURER'
export const LOGIN_MANUFACTURER_SUCCESS = '[AUTH] LOGIN_MANUFACTURER_SUCCESS'
export const LOGIN_MANUFACTURER_ERROR = '[AUTH] LOGIN_MANUFACTURER_ERROR'
export const RESET_NEW_MANUFACTURER_PASSWORD = '[AUTH] RESET_NEW_MANUFACTURER_PASSWORD'
export const RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS = '[AUTH] RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS'
export const RESET_NEW_MANUFACTURER_PASSWORD_ERROR= '[AUTH] RESET_NEW_MANUFACTURER_PASSWORD_ERROR'


export const GET_CURRENT_SESSION = `[AUTH] GET_CURRENT_SESSION`
export const GET_CURRENT_SESSION_ERROR = '[AUTH] GET_CURRENT_SESSION_ERROR'
export const SET_ITEM_TO_CART = '[AUTH] SET_ITEM_TO_CART'
export const UPDATE_ITEM_TO_CART = '[AUTH] UPDATE_ITEM_TO_CART'
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const IS_VERIFIED = 'IS_VERIFIED'
export const LOGOUT = `[AUTH] LOGOUT`
export const IS_NOT_VERIFIED = `IS_NOT_VERIFIED`
export const SET_STOCK_COUNT = `SET_STOCK_COUNT`
export const CLEAR_STOCK_COUNT = `CLEAR_STOCK_COUNT`
export const SET_CONFIRMATION_PAYLOAD = 'SET_CONFIRMATION_PAYLOAD'
export const CLEAR_CONFIRMATION_PAYLOAD = 'CLEAR_CONFIRMATION_PAYLOAD'
export const SET_APPROVE_DATA = 'SET_APPROVE_DATA'
export const CLEAR_APPROVE_DATA = 'CLEAR_APPROVE_DATA'
export type ActionTypes =
  | typeof IS_LOGGING_IN
  | typeof DONE_LOGGING_IN
  | typeof REGISTERING
  | typeof DONE_REGISTERING
  | typeof SET_USER
  | typeof LOGOUT_USER
  | typeof LOGIN_MANUFACTURER
  | typeof LOGIN_MANUFACTURER_ERROR
  | typeof LOGIN_MANUFACTURER_SUCCESS
  | typeof RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS
  | typeof RESET_NEW_MANUFACTURER_PASSWORD_ERROR
  | typeof RESET_NEW_MANUFACTURER_PASSWORD
  | typeof GET_CURRENT_SESSION
  | typeof GET_CURRENT_SESSION_ERROR
  | typeof LOGOUT
  | typeof SET_ITEM_TO_CART
  | typeof CLEAR_CART
  | typeof IS_VERIFIED
  | typeof IS_NOT_VERIFIED
  | typeof SET_STOCK_COUNT
  | typeof CLEAR_STOCK_COUNT
  | typeof SET_CONFIRMATION_PAYLOAD
  | typeof CLEAR_CONFIRMATION_PAYLOAD
  | typeof SET_APPROVE_DATA
  | typeof CLEAR_APPROVE_DATA
  | typeof UPDATE_ITEM_TO_CART
