import { FC } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NavLink } from '../layouts.model';

export const NavItem: FC<{
  item: NavLink;
  onSelect?: (item: NavLink) => void;
}> = ({ item, onSelect }) => {
  return (
    <Link
      className={`d-flex do-not-print my-3 mx-2 py-2 pl-4 w-100   ${
        item.isSelected ? 'selected' : ''
      }`}
      to={item.path as string}
      onClick={(e) =>{
        if (item.type === 'button') {
          e.preventDefault()
        }

        onSelect && onSelect(item)
      }}
    >
      <Nav.Item className="nav-item" key={item.key}>
        {item.render ? item.render(item) : item.title}
      </Nav.Item>
    </Link>
  );
};
