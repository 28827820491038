import { SearchOutlined } from '@ant-design/icons';

interface CustomInputProps extends Partial<HTMLInputElement> {
  onChange?: (arg: string) => void;
  iconColor?: string;
}

export const Search: React.FC<CustomInputProps> = ({
  className,
  type,
  placeholder,
  value,
  onChange,
  iconColor,
}) => {
  return (
    <div className="d-flex input-search fullwidth align-items-center p-1">
      <SearchOutlined
        className="icon-padding"
        style={{ fontSize: '16px', color: iconColor }}
      />
      <input
        className={`input-search ${className || ''}`}
        placeholder={placeholder}
        value={value}
        type={type || 'text'}
        onChange={(e) => onChange && onChange(e.target.value)}
        name="name"
      />
    </div>
  );
};
