/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWard = /* GraphQL */ `
  mutation CreateWard($ward: WardInput) {
    createWard(ward: $ward)
  }
`;
export const deleteWard = /* GraphQL */ `
  mutation DeleteWard($wardId: ID!) {
    deleteWard(wardId: $wardId)
  }
`;
export const deleteMultipleWards = /* GraphQL */ `
  mutation DeleteMultipleWards($wardIds: [String]) {
    deleteMultipleWards(wardIds: $wardIds)
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission($permission: PermissionInput) {
    createPermission(permission: $permission)
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole($role: RoleInput) {
    createRole(role: $role)
  }
`;
export const createDMARole = /* GraphQL */ `
  mutation CreateDMARole($role: RoleInput) {
    createDMARole(role: $role)
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole($rolesId: ID!, $role: RoleInput) {
    updateRole(rolesId: $rolesId, role: $role)
  }
`;
export const updateDMARole = /* GraphQL */ `
  mutation UpdateDMARole($dmaRolesId: ID!, $role: RoleInput) {
    updateDMARole(dmaRolesId: $dmaRolesId, role: $role)
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission($permissionId: ID!, $permission: PermissionInput) {
    updatePermission(permissionId: $permissionId, permission: $permission)
  }
`;
export const createManufacturer = /* GraphQL */ `
  mutation CreateManufacturer(
    $email: String
    $company_name: String
    $phoneNumber: String
    $name: String
    $location: String
    $group: String
  ) {
    createManufacturer(
      email: $email
      company_name: $company_name
      phoneNumber: $phoneNumber
      name: $name
      location: $location
      group: $group
    ) {
      User {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      code
      message
    }
  }
`;
export const createUserWithRole = /* GraphQL */ `
  mutation CreateUserWithRole(
    $email: String
    $rolename: String
    $phoneNumber: String
    $name: String
    $location: String
  ) {
    createUserWithRole(
      email: $email
      rolename: $rolename
      phoneNumber: $phoneNumber
      name: $name
      location: $location
    ) {
      User {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      code
      message
    }
  }
`;
export const dmaCreateBranchUser = /* GraphQL */ `
  mutation DmaCreateBranchUser(
    $email: String!
    $rolename: String
    $phoneNumber: String!
    $name: String!
    $dmaId: ID!
    $branchId: ID!
    $location: String
  ) {
    dmaCreateBranchUser(
      email: $email
      rolename: $rolename
      phoneNumber: $phoneNumber
      name: $name
      dmaId: $dmaId
      branchId: $branchId
      location: $location
    ) {
      User {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      code
      message
    }
  }
`;
export const dmaCreateTransporter = /* GraphQL */ `
  mutation DmaCreateTransporter(
    $email: String
    $phoneNumber: String!
    $name: String!
    $dmaId: ID!
    $location: String
    $nationalId: ID!
  ) {
    dmaCreateTransporter(
      email: $email
      phoneNumber: $phoneNumber
      name: $name
      dmaId: $dmaId
      location: $location
      nationalId: $nationalId
    ) {
      User {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      code
      message
    }
  }
`;
export const enableAdmin = /* GraphQL */ `
  mutation EnableAdmin($Username: String) {
    enableAdmin(Username: $Username)
  }
`;
export const disableAdmin = /* GraphQL */ `
  mutation DisableAdmin($Username: String) {
    disableAdmin(Username: $Username)
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $email: String!
    $phoneNumber: String!
    $group: String
    $name: String
  ) {
    createAdmin(
      email: $email
      phoneNumber: $phoneNumber
      group: $group
      name: $name
    ) {
      User {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        UserAttributes {
          Name
          Value
        }
      }
      message
      code
    }
  }
`;
export const updateAdminRole = /* GraphQL */ `
  mutation UpdateAdminRole($Username: String!, $group: String!) {
    updateAdminRole(Username: $Username, group: $group)
  }
`;
export const dmaCreateCollectionCentreUser = /* GraphQL */ `
  mutation DmaCreateCollectionCentreUser(
    $email: String!
    $rolename: String
    $phoneNumber: String!
    $name: String!
    $dmaId: ID!
    $collectionCentreId: ID!
    $location: String
  ) {
    dmaCreateCollectionCentreUser(
      email: $email
      rolename: $rolename
      phoneNumber: $phoneNumber
      name: $name
      dmaId: $dmaId
      collectionCentreId: $collectionCentreId
      location: $location
    ) {
      User {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      code
      message
    }
  }
`;
export const dmaDisableTransporter = /* GraphQL */ `
  mutation DmaDisableTransporter($username: String) {
    dmaDisableTransporter(username: $username)
  }
`;
export const dmaEnableTransporter = /* GraphQL */ `
  mutation DmaEnableTransporter($username: String) {
    dmaEnableTransporter(username: $username)
  }
`;
export const createTempDma = /* GraphQL */ `
  mutation CreateTempDma($dma: TempDMAInput) {
    createTempDma(dma: $dma) {
      data
      code
      message
    }
  }
`;
export const createAuditLog = /* GraphQL */ `
  mutation CreateAuditLog($auditLog: AuditLogInput!) {
    createAuditLog(auditLog: $auditLog) {
      code
      status
      data {
        auditId
        username
        action
        date
        event
      }
      message
    }
  }
`;
export const checkDmaPhoneNumber = /* GraphQL */ `
  mutation CheckDmaPhoneNumber($phoneNumber: String!) {
    checkDmaPhoneNumber(phoneNumber: $phoneNumber)
  }
`;
export const createAdvert = /* GraphQL */ `
  mutation CreateAdvert($advert: AdvertInput) {
    createAdvert(advert: $advert) {
      status
      code
      data
    }
  }
`;
export const updateAdvert = /* GraphQL */ `
  mutation UpdateAdvert($advertId: ID!, $advertss: AdvertInput) {
    updateAdvert(advertId: $advertId, advertss: $advertss) {
      adcategory {
        adcategoryId
        name
        creationDate
        modificationDate
      }
      creationDate
      description
      advertId
      modificationDate
      name
      time
      action
      image
    }
  }
`;
export const deleteAdvertById = /* GraphQL */ `
  mutation DeleteAdvertById($advertId: ID!) {
    deleteAdvertById(advertId: $advertId) {
      adcategory {
        adcategoryId
        name
        creationDate
        modificationDate
      }
      creationDate
      description
      advertId
      modificationDate
      name
      time
      action
      image
    }
  }
`;
export const createAdCategory = /* GraphQL */ `
  mutation CreateAdCategory($adcategory: AdCategoryInput) {
    createAdCategory(adcategory: $adcategory) {
      status
      code
      data
    }
  }
`;
export const updateAdCategory = /* GraphQL */ `
  mutation UpdateAdCategory($adcategoryId: ID!, $adcategory: AdCategoryInput) {
    updateAdCategory(adcategoryId: $adcategoryId, adcategory: $adcategory) {
      adcategoryId
      name
      creationDate
      modificationDate
    }
  }
`;
export const deleteAdCategoryById = /* GraphQL */ `
  mutation DeleteAdCategoryById($adcategoryId: ID!) {
    deleteAdCategoryById(adcategoryId: $adcategoryId) {
      adcategoryId
      name
      creationDate
      modificationDate
    }
  }
`;
export const createAdCompany = /* GraphQL */ `
  mutation CreateAdCompany($adcompany: AdCompanyInput) {
    createAdCompany(adcompany: $adcompany) {
      status
      code
      data
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart($carts: CartInput) {
    createCart(carts: $carts) {
      status
      code
      data
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart($cartId: ID!, $carts: CartInput) {
    updateCart(cartId: $cartId, carts: $carts) {
      cartId
      cartItems {
        product {
          manufacturerUserId
          description
          productId
          name
          price
          unit
          type
          image
          weight
          quantity
          company_name
          manufacturerId
        }
        quantity
        total
      }
      dmaId
      userId
      branchId
      cartStatus
      creationDate
      modificationDate
      paymentStatus
      paymentMethod
      mpesaTransactionId
      total
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart($cartId: ID!, $dmaId: ID!) {
    deleteCart(cartId: $cartId, dmaId: $dmaId) {
      cartId
      cartItems {
        product {
          manufacturerUserId
          description
          productId
          name
          price
          unit
          type
          image
          weight
          quantity
          company_name
          manufacturerId
        }
        quantity
        total
      }
      dmaId
      userId
      branchId
      cartStatus
      creationDate
      modificationDate
      paymentStatus
      paymentMethod
      mpesaTransactionId
      total
    }
  }
`;
export const publishCartUpdate = /* GraphQL */ `
  mutation PublishCartUpdate($cartId: ID) {
    publishCartUpdate(cartId: $cartId) {
      cartId
      cartItems {
        product {
          manufacturerUserId
          description
          productId
          name
          price
          unit
          type
          image
          weight
          quantity
          company_name
          manufacturerId
        }
        quantity
        total
      }
      dmaId
      userId
      branchId
      cartStatus
      creationDate
      modificationDate
      paymentStatus
      paymentMethod
      mpesaTransactionId
      total
    }
  }
`;
export const transporterAddFarmerDelivery = /* GraphQL */ `
  mutation TransporterAddFarmerDelivery(
    $farmerDelivery: TransporterAddFarmerDeliveryInput
  ) {
    transporterAddFarmerDelivery(farmerDelivery: $farmerDelivery) {
      data {
        farmerId
        collectionCenterId
        transporterId
        deliveryDate
        containerNumber
        product
        quantity
        unitPrice
        status
      }
      code
      message
    }
  }
`;
export const addFarmerTransaction = /* GraphQL */ `
  mutation AddFarmerTransaction($farmerTransaction: FarmerTransactionInput) {
    addFarmerTransaction(farmerTransaction: $farmerTransaction) {
      data
      code
      message
    }
  }
`;
export const createDmaAndMainBranch = /* GraphQL */ `
  mutation CreateDmaAndMainBranch($dma: DMAInput) {
    createDmaAndMainBranch(dma: $dma) {
      data
      code
      message
    }
  }
`;
export const createBranch = /* GraphQL */ `
  mutation CreateBranch($branch: BranchInput) {
    createBranch(branch: $branch) {
      status
      code
      data
    }
  }
`;
export const createCollectionCentre = /* GraphQL */ `
  mutation CreateCollectionCentre($CollectionCentre: CollectionCentreInput) {
    createCollectionCentre(CollectionCentre: $CollectionCentre) {
      data
      code
      message
    }
  }
`;
export const addFarmer = /* GraphQL */ `
  mutation AddFarmer($farmer: AddFarmerInput) {
    addFarmer(farmer: $farmer) {
      code
      message
      data {
        farmerId
        dmaId
        national_id
        member_number
        phone
        first_name
        middle_name
        last_name
        route
        supply_category
        MCC
        gender
        village
      }
    }
  }
`;
export const collectionCentreAddMilkDelivery = /* GraphQL */ `
  mutation CollectionCentreAddMilkDelivery(
    $milkDelivery: CollectionCenterAddMilkDeliveryInput
  ) {
    collectionCentreAddMilkDelivery(milkDelivery: $milkDelivery) {
      data {
        farmerId
        collectionCenterId
        transporterId
        deliveryDate
        containerNumber
        product
        quantity
        unitPrice
        status
      }
      code
      message
    }
  }
`;
export const approveMilkDelivery = /* GraphQL */ `
  mutation ApproveMilkDelivery($milkDeliveries: ApproveMilkDeliveryInput) {
    approveMilkDelivery(milkDeliveries: $milkDeliveries) {
      data
      code
      message
    }
  }
`;
export const updateMilkDelivery = /* GraphQL */ `
  mutation UpdateMilkDelivery($delivery: UpdateMilkDeliveryInput) {
    updateMilkDelivery(delivery: $delivery) {
      code
      message
    }
  }
`;
export const deleteMilkDelivery = /* GraphQL */ `
  mutation DeleteMilkDelivery($deliveryId: ID!, $deliveryDate: String) {
    deleteMilkDelivery(deliveryId: $deliveryId, deliveryDate: $deliveryDate) {
      code
      message
    }
  }
`;
export const createDmaPartnership = /* GraphQL */ `
  mutation CreateDmaPartnership($cooperativeId: ID!, $agrovetId: ID!) {
    createDmaPartnership(cooperativeId: $cooperativeId, agrovetId: $agrovetId) {
      data
      code
      message
    }
  }
`;
export const updateKycStatus = /* GraphQL */ `
  mutation UpdateKycStatus($dmaId: ID!, $status: KYCSTATUS) {
    updateKycStatus(dmaId: $dmaId, status: $status) {
      data
      code
      message
    }
  }
`;
export const removeDmaPartnership = /* GraphQL */ `
  mutation RemoveDmaPartnership($cooperativeId: ID!, $agrovetId: ID!) {
    removeDmaPartnership(cooperativeId: $cooperativeId, agrovetId: $agrovetId) {
      data
      code
      message
    }
  }
`;
export const updateDmaProfileDetailsByDmaId = /* GraphQL */ `
  mutation UpdateDmaProfileDetailsByDmaId($dmaProfile: UpdateDmaProfileInput) {
    updateDmaProfileDetailsByDmaId(dmaProfile: $dmaProfile) {
      data
      code
      message
    }
  }
`;
export const deleteDma = /* GraphQL */ `
  mutation DeleteDma($dmaId: ID!) {
    deleteDma(dmaId: $dmaId) {
      status
      code
      message
    }
  }
`;
export const createDmastock = /* GraphQL */ `
  mutation CreateDmastock($dmastock: DmastockInput) {
    createDmastock(dmastock: $dmastock) {
      status
      code
      data {
        stockRequestId
        branchId
        productId
        dmaId
        name
        type
        unit
        quantity
        status
        creationDate
        product {
          description
          productId
          name
          price
          subCategoryName
          unit
          type
          image
          quantity
          status
          weight
          manufacturerUserId
          creationDate
          modificationDate
          subCategoryId
          rebates
          dmaPrice
          company_name
        }
        branch {
          branchId
          dmaId
          branchName
          location
          isHQ
          phoneNumber
          mail
          ward
          creationDate
        }
      }
      message
    }
  }
`;
export const updateDmastock = /* GraphQL */ `
  mutation UpdateDmastock($dmastockId: ID!, $dmastocks: DmastockInput) {
    updateDmastock(dmastockId: $dmastockId, dmastocks: $dmastocks) {
      dmastockId
      manufacturerUserId
      manufacturerName
      dmaUserId
      dmaId
      branchId
      creationDate
      description
      modificationDate
      name
      buying_price
      selling_price
      stockSubCategoryId
      unit
      type
      image
      quantity
      status
      weight
      bulkUploadProcessStatus
      bulkUploadProcessId
      rebates
    }
  }
`;
export const deleteDmastockById = /* GraphQL */ `
  mutation DeleteDmastockById($dmastockId: ID!) {
    deleteDmastockById(dmastockId: $dmastockId) {
      dmastockId
      manufacturerUserId
      manufacturerName
      dmaUserId
      dmaId
      branchId
      creationDate
      description
      modificationDate
      name
      buying_price
      selling_price
      stockSubCategoryId
      unit
      type
      image
      quantity
      status
      weight
      bulkUploadProcessStatus
      bulkUploadProcessId
      rebates
    }
  }
`;
export const updateListDmastock = /* GraphQL */ `
  mutation UpdateListDmastock($branchId: ID!, $bulkAction: String) {
    updateListDmastock(branchId: $branchId, bulkAction: $bulkAction)
  }
`;
export const approveDmastock = /* GraphQL */ `
  mutation ApproveDmastock($bulkUploadId: [ID!], $bulkAction: String) {
    approveDmastock(bulkUploadId: $bulkUploadId, bulkAction: $bulkAction)
  }
`;
export const createStockRequest = /* GraphQL */ `
  mutation CreateStockRequest($stockRequest: StockRequestInput) {
    createStockRequest(stockRequest: $stockRequest) {
      status
      code
      data {
        stockRequestId
        branchId
        productId
        dmaId
        name
        type
        unit
        quantity
        status
        creationDate
        product {
          description
          productId
          name
          price
          subCategoryName
          unit
          type
          image
          quantity
          status
          weight
          manufacturerUserId
          creationDate
          modificationDate
          subCategoryId
          rebates
          dmaPrice
          company_name
        }
        branch {
          branchId
          dmaId
          branchName
          location
          isHQ
          phoneNumber
          mail
          ward
          creationDate
        }
      }
      message
    }
  }
`;
export const approveStockRequest = /* GraphQL */ `
  mutation ApproveStockRequest($stockRequestId: ID!) {
    approveStockRequest(stockRequestId: $stockRequestId) {
      status
      code
      data {
        stockRequestId
        branchId
        productId
        dmaId
        name
        type
        unit
        quantity
        status
        creationDate
        product {
          description
          productId
          name
          price
          subCategoryName
          unit
          type
          image
          quantity
          status
          weight
          manufacturerUserId
          creationDate
          modificationDate
          subCategoryId
          rebates
          dmaPrice
          company_name
        }
        branch {
          branchId
          dmaId
          branchName
          location
          isHQ
          phoneNumber
          mail
          ward
          creationDate
        }
      }
      message
    }
  }
`;
export const rejectStockRequest = /* GraphQL */ `
  mutation RejectStockRequest($stockRequestId: ID!) {
    rejectStockRequest(stockRequestId: $stockRequestId) {
      status
      code
      data {
        stockRequestId
        branchId
        productId
        dmaId
        name
        type
        unit
        quantity
        status
        creationDate
        product {
          description
          productId
          name
          price
          subCategoryName
          unit
          type
          image
          quantity
          status
          weight
          manufacturerUserId
          creationDate
          modificationDate
          subCategoryId
          rebates
          dmaPrice
          company_name
        }
        branch {
          branchId
          dmaId
          branchName
          location
          isHQ
          phoneNumber
          mail
          ward
          creationDate
        }
      }
      message
    }
  }
`;
export const createFarmerOrder = /* GraphQL */ `
  mutation CreateFarmerOrder($farmerOrderInput: FarmerOrderCreateInput) {
    createFarmerOrder(farmerOrderInput: $farmerOrderInput) {
      status
      code
      data {
        farmerOrderId
        memberNumber
        farmerName
        totalCost
        paymentMethod
        product {
          quantity
          product {
            dmastockId
            manufacturerUserId
            manufacturerName
            dmaUserId
            dmaId
            branchId
            creationDate
            description
            modificationDate
            name
            buying_price
            selling_price
            stockSubCategoryId
            unit
            type
            image
            quantity
            status
            weight
            bulkUploadProcessStatus
            bulkUploadProcessId
            rebates
          }
          price
          selling_price
        }
        creationDate
        paymentStatus
        farmerOrderStatus
        dmaName
        dmaUserId
        dmaId
        branchId
        mpesaTransactionId
      }
      message
    }
  }
`;
export const updateFarmerOrder = /* GraphQL */ `
  mutation UpdateFarmerOrder(
    $farmerOrderId: ID!
    $farmerOrder: FarmerOrderUpdateInput
  ) {
    updateFarmerOrder(
      farmerOrderId: $farmerOrderId
      farmerOrder: $farmerOrder
    ) {
      farmerOrderId
      memberNumber
      farmerName
      totalCost
      paymentMethod
      product {
        quantity
        product {
          dmastockId
          manufacturerUserId
          manufacturerName
          dmaUserId
          dmaId
          branchId
          creationDate
          description
          modificationDate
          name
          buying_price
          selling_price
          stockSubCategoryId
          unit
          type
          image
          quantity
          status
          weight
          bulkUploadProcessStatus
          bulkUploadProcessId
          rebates
        }
        price
        selling_price
      }
      creationDate
      paymentStatus
      farmerOrderStatus
      dmaName
      dmaUserId
      dmaId
      branchId
      mpesaTransactionId
    }
  }
`;
export const deleteFarmerOrderById = /* GraphQL */ `
  mutation DeleteFarmerOrderById($farmerOrderId: ID!) {
    deleteFarmerOrderById(farmerOrderId: $farmerOrderId) {
      farmerOrderId
      memberNumber
      farmerName
      totalCost
      paymentMethod
      product {
        quantity
        product {
          dmastockId
          manufacturerUserId
          manufacturerName
          dmaUserId
          dmaId
          branchId
          creationDate
          description
          modificationDate
          name
          buying_price
          selling_price
          stockSubCategoryId
          unit
          type
          image
          quantity
          status
          weight
          bulkUploadProcessStatus
          bulkUploadProcessId
          rebates
        }
        price
        selling_price
      }
      creationDate
      paymentStatus
      farmerOrderStatus
      dmaName
      dmaUserId
      dmaId
      branchId
      mpesaTransactionId
    }
  }
`;
export const deleteDmaStockList = /* GraphQL */ `
  mutation DeleteDmaStockList($stockIds: [ID]!) {
    deleteDmaStockList(stockIds: $stockIds)
  }
`;
export const updateBulkUploadedDmaStock = /* GraphQL */ `
  mutation UpdateBulkUploadedDmaStock(
    $dmastockId: ID!
    $dmastocks: DmaBulkUploadStockInput
  ) {
    updateBulkUploadedDmaStock(dmastockId: $dmastockId, dmastocks: $dmastocks) {
      dmastockId
      manufacturerUserId
      manufacturerName
      dmaUserId
      dmaId
      branchId
      creationDate
      description
      modificationDate
      name
      buying_price
      selling_price
      stockSubCategoryId
      unit
      type
      image
      quantity
      status
      weight
      bulkUploadProcessStatus
      bulkUploadProcessId
      rebates
    }
  }
`;
export const addDmastock = /* GraphQL */ `
  mutation AddDmastock($dmastock: AddDmaBulkUploadStockInput) {
    addDmastock(dmastock: $dmastock)
  }
`;
export const deleteAllDmaStock = /* GraphQL */ `
  mutation DeleteAllDmaStock($branchId: ID!) {
    deleteAllDmaStock(branchId: $branchId)
  }
`;
export const createDmaStockReconciliationRecord = /* GraphQL */ `
  mutation CreateDmaStockReconciliationRecord(
    $record: DmaStockReonciliationRecordInput!
  ) {
    createDmaStockReconciliationRecord(record: $record)
  }
`;
export const updateDmaStockReconciliationRecord = /* GraphQL */ `
  mutation UpdateDmaStockReconciliationRecord(
    $record: DmaStockReonciliationRecordInput!
  ) {
    updateDmaStockReconciliationRecord(record: $record)
  }
`;
export const createManufacturerAndMainHub = /* GraphQL */ `
  mutation CreateManufacturerAndMainHub($manufacturerInput: ManufacturerInput) {
    createManufacturerAndMainHub(manufacturerInput: $manufacturerInput) {
      data {
        User {
          Username
          UserCreateDate
          UserLastModifiedDate
          Enabled
          UserStatus
          Attributes {
            Name
            Value
          }
        }
        companyName
        location
        name
        phoneNumber
        address
        email
      }
      code
      message
    }
  }
`;
export const createHub = /* GraphQL */ `
  mutation CreateHub($hubInput: CreateHubInput) {
    createHub(hubInput: $hubInput) {
      data {
        location
        hubName
        phoneNumber
        email
        hubId
        manufacturerId
        address
        creationDate
        modificationDate
      }
      code
      message
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder($order: OrderInput) {
    createOrder(order: $order) {
      status
      code
      data
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $orderId: ID!
    $manufacturerId: ID!
    $orders: UpdateOrderInput
  ) {
    updateOrder(
      orderId: $orderId
      manufacturerId: $manufacturerId
      orders: $orders
    ) {
      orderId
      manufacturerId
      dmaId
      branchId
      orderItems {
        product {
          productId
          manufacturerUserId
          description
          name
          company_name
          price
          unit
          type
          image
          weight
          quantity
        }
        quantity
        availableQuantity
        receivedQuantity
        total
      }
      orderStatus
      paymentStatus
      paymentMethod
      creationDate
      modificationDate
      rebates
    }
  }
`;
export const paymentPush = /* GraphQL */ `
  mutation PaymentPush($request: PushStkInput) {
    paymentPush(request: $request) {
      MerchantRequestID
      CheckoutRequestID
      ResponseCode
      ResponseDescription
      CustomerMessage
      requestId
      errorCode
      errorMessage
    }
  }
`;
export const paymentB2B = /* GraphQL */ `
  mutation PaymentB2B($request: B2BInput) {
    paymentB2B(request: $request) {
      status
      code
      data
    }
  }
`;
export const paymentLipaNaBonga = /* GraphQL */ `
  mutation PaymentLipaNaBonga($request: B2BInput) {
    paymentLipaNaBonga(request: $request) {
      status
      code
      data
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($product: ProductInput) {
    createProduct(product: $product) {
      status
      code
      data
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($productId: ID!, $produces: ProductInput) {
    updateProduct(productId: $productId, produces: $produces) {
      manufacturerUserId
      creationDate
      description
      productId
      modificationDate
      name
      price
      subCategoryId
      subCategoryName
      unit
      type
      image
      quantity
      status
      weight
      rebates
      dmaPrice
      company_name
      manufacturerId
      hubId
    }
  }
`;
export const updateMultipleProducts = /* GraphQL */ `
  mutation UpdateMultipleProducts($productIds: [ID!], $status: String) {
    updateMultipleProducts(productIds: $productIds, status: $status) {
      manufacturerUserId
      creationDate
      description
      productId
      modificationDate
      name
      price
      subCategoryId
      subCategoryName
      unit
      type
      image
      quantity
      status
      weight
      rebates
      dmaPrice
      company_name
      manufacturerId
      hubId
    }
  }
`;
export const deleteProductById = /* GraphQL */ `
  mutation DeleteProductById($productId: ID!) {
    deleteProductById(productId: $productId) {
      manufacturerUserId
      creationDate
      description
      productId
      modificationDate
      name
      price
      subCategoryId
      subCategoryName
      unit
      type
      image
      quantity
      status
      weight
      rebates
      dmaPrice
      company_name
      manufacturerId
      hubId
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($category: CategoryInput!) {
    createCategory(category: $category) {
      status
      code
      data
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($categoryId: ID!, $category: CategoryInput!) {
    updateCategory(categoryId: $categoryId, category: $category) {
      categoryId
      name
      subCategory {
        subCategoryId
        categoryId
        name
        creationDate
        modificationDate
        image
      }
      creationDate
      modificationDate
      image
    }
  }
`;
export const deleteCategoryById = /* GraphQL */ `
  mutation DeleteCategoryById($categoryId: ID!) {
    deleteCategoryById(categoryId: $categoryId) {
      categoryId
      name
      subCategory {
        subCategoryId
        categoryId
        name
        creationDate
        modificationDate
        image
      }
      creationDate
      modificationDate
      image
    }
  }
`;
export const createSubCategory = /* GraphQL */ `
  mutation CreateSubCategory($subCategory: SubCategoryInput!) {
    createSubCategory(subCategory: $subCategory) {
      status
      code
      data
    }
  }
`;
export const updateSubCategory = /* GraphQL */ `
  mutation UpdateSubCategory(
    $subCategoryId: ID!
    $subCategory: SubCategoryInput!
  ) {
    updateSubCategory(
      subCategoryId: $subCategoryId
      subCategory: $subCategory
    ) {
      subCategoryId
      categoryId
      name
      creationDate
      modificationDate
      image
    }
  }
`;
export const deleteSubCategoryById = /* GraphQL */ `
  mutation DeleteSubCategoryById($subCategoryId: ID!) {
    deleteSubCategoryById(subCategoryId: $subCategoryId) {
      subCategoryId
      categoryId
      name
      creationDate
      modificationDate
      image
    }
  }
`;
