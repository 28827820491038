import React, { FC } from 'react';

import { ProductImage } from '../ProductImage/ProductImage';

export const IDProductUploadImages: FC<{ images: Array<string | undefined | null> }> = ({ images }) => {
    return (
        <div
            className="d-flex align-items-evenly justify-content-between w-100 flex-nowrap overflow-x-auto"
            style={{ height: 180 }}
        >
            {images.length > 1 &&
                images.map((image, index) => (
                    <div key={index} className="d-inline-flex ml-3" style={{ height: 180, width: 180 }}>
                        <ProductImage imgUrl={image || ''} />
                    </div>
                ))}
        </div>
    );
};
