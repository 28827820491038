import { ChangeEvent, FC } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { ProductInput } from '../../../API';

const labelStyle = {
  fontSize: '0.85rem',
  fontWeight: 600,
};


interface ProductHubSelectProps {
  type?: 'text' | 'number' | 'textarea';
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  data: any[];
  className?: string;
  name: keyof ProductInput;
}

export const ProductHubSelect: FC<ProductHubSelectProps> = (props) => {
  return (
    <Form.Group>
       { props.label && (<Form.Label className="auth-label mb-2 w-100" style={labelStyle}>
        {props.label }
      </Form.Label>) }
      <Form.Control as="select" bsPrefix='form-control'
              {...props}
          >
              <option value={''} key={''}>
                  Select Hub
              </option>
            {props.data.map((hub, k) => {
              
                return (
                  
                    <option value={hub.hubId} key={k}>
                        {hub.hubName}
                    </option>
                );
            })}
        </Form.Control>
    </Form.Group>
  );
};
