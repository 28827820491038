import React from "react";
import Amplify, { Auth } from "aws-amplify";
import reduxStore from "./redux/store";
import Routes from "./config/Routes";
import config from "./aws-config";
import "antd/dist/antd.css";
import { getCartCount } from "./redux/actions/productActions";
import { useDispatch } from 'react-redux';
import { getCurrentSession } from './redux/actions/authActions';
require("dotenv").config();

const getIdToken = async () => {
    try {
        return {
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        };
    } catch (e) {
        console.debug(e);
    }
};

Amplify.configure({ ...config, graphql_headers: getIdToken });

const App: React.FC = () => {
  const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getCurrentSession());
        if (localStorage.user) {
          const data = JSON.parse(localStorage.user);
          reduxStore().store.dispatch(getCartCount(data.username));
        }   
    }, [dispatch]);

  return (
        <Routes />
  );
};

export default App;
