import {
  CLEAR_CART,
  GET_CURRENT_SESSION,
  IS_NOT_VERIFIED,
  IS_VERIFIED,
  LOGIN_MANUFACTURER_ERROR,
  LOGIN_MANUFACTURER_SUCCESS,
  LOGOUT,
  REMOVE_ITEM_FROM_CART,
  RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS,
  SET_ITEM_TO_CART,SET_STOCK_COUNT,
  CLEAR_STOCK_COUNT
} from "./../actionTypes";
import { PayloadAction } from "@reduxjs/toolkit";

import { AuthReducerState } from "./reducer.model";
import {
  IS_LOGGING_IN,
  DONE_LOGGING_IN,
  SET_USER,
  LOGIN_MANUFACTURER,
} from "../actionTypes";
import { FetchCartByUserIdQuery } from "../../API";

const initialState: AuthReducerState = {
  isAuthenticated: false,
  cart: {} as FetchCartByUserIdQuery["fetchCartByUserId"],
  isStockCount: false
};
const auth = (state = initialState, action: PayloadAction<any>) => {
  //check this is not an error.
  let authError: any;
  let authUser: any;
  if(action?.payload?.error?.message){
    authError = action?.payload?.error?.message
  }else{
    authUser = action?.payload
  }
 
  switch (action.type) {
    case IS_LOGGING_IN:
      return { ...state, isLoggingIn: true };
    case DONE_LOGGING_IN:
      return { ...state, isLoggingIn: false };
    case SET_USER:
      return { ...state, user: authUser, isAuthenticated: true };
    case LOGIN_MANUFACTURER:
      return {
        ...state,
        isLoggingIn: true,
        isManufacturer: true,
        isNewUser: false,
      };
    case IS_NOT_VERIFIED:
      return { ...state, verified: false };
    case IS_VERIFIED:
      return { ...state, verified: true };
    case LOGIN_MANUFACTURER_SUCCESS: {
     
      const session = authUser;

      const isNewUser =
        session.challengeName &&
        session.challengeName.toLowerCase() === "new_password_required";
        return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: !isNewUser,
        isNewUser,
        isManufacturer: true,
        user: session.user,
      };
    }
    case RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS:
      const session = { ...state, challengeName: "" };
      return { ...state, isNewUser: false, session };
    case GET_CURRENT_SESSION:
      return {
        ...state,
        isAuthenticated: !!authUser,
        session: authUser,
      };
    case LOGIN_MANUFACTURER_ERROR:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        isManufacturer: true,
        error: authError
      };
    case LOGOUT:
      return { ...initialState };
    case SET_STOCK_COUNT:
        return { ...state, isStockCount: true};
    case CLEAR_STOCK_COUNT:
        return { ...state, isStockCount: false};
      
    case CLEAR_CART:
      return { ...state, cart: {} };
    case SET_ITEM_TO_CART:
      return { ...state, cart: authUser };
    case REMOVE_ITEM_FROM_CART:
      return { ...state, cart: authUser };
    default:
      return state;
  }
};

export default auth;
