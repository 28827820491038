import {
  DeleteOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Popover } from 'antd';
import { NavLink } from 'react-router-dom';
import { NestedProducts } from '../../../API';
import { ProductImage } from '../../../components/ProductImage/ProductImage';
import { formatDate } from '../../../utills/utils';
import _ from 'lodash';
import { capitalizeFirstLetter } from '../../sales/helpers';


const productImageCss = {
  height: '3rem',
  display: 'flex',
  flexWrap: 'nowrap' as 'nowrap',
  alignItems: 'center',
};

const productImageWrapperCss = {
  height: '100%',
  width: '4rem',
  marginRight: '.2rem',
};

const maskProductId = (productId?: string) => {
  if (!productId) return productId
  return productId.substr(0, 4) + '-****-' + productId.substring(productId.length - 4);
}

export const formatUnitQuantity = (unitQuantity?: string | null) => {
  if (!unitQuantity) return unitQuantity;
  return unitQuantity.replaceAll(/[^0-9]/g, '') + ' KG'
}


export const getInvalidProductsColumns = () => [
  {
    title: 'ID',
    dataIndex: 'productId',
    key: 'productId',
  },
  {
    title: 'Manufacturer Name',
    dataIndex: 'company_name',
    key: 'company_name',
  },
  {
    title: 'Input Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Input Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },

  {
    title: 'Total Price (Ksh)',
    dataIndex: 'price',
    key: 'price',
    render: (_: unknown, data?: any) => {
      return <div>{data.quantity * data.price || 'N/A'}</div>;
    },
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_: unknown, data?: any) => {
      return (
        <div className='rejected'>Rejected</div>
      );
    },
  },
];

export const getUploadColumns = (
  productDetailsId: string,
  setView: React.Dispatch<React.SetStateAction<string>>,
  setProductDetailsId: React.Dispatch<React.SetStateAction<string | null>>,
  deleteProduct: (id: string) => Promise<void>
) => [
  {
    title: 'ID',
    dataIndex: 'productId',
    key: 'productId',
  },
  {
    title: 'Manufacturer Name',
    dataIndex: 'company_name',
    key: 'company_name',
  },
  {
    title: 'Input Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Input Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },

  {
    title: 'Total Price (Ksh)',
    dataIndex: 'price',
    key: 'price',
    render: (_: unknown, data?: any) => {
      return <div>{data.quantity * data.price}</div>;
    },
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_: unknown, data?: any) => {
      return (
        <div className={data.status}>{capitalizeFirstLetter(data.status)}</div>
      );
    },
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (action: any, data: any) => {
      const content = (
        <>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setProductDetailsId(data.productId);
              setView('uploadDetails');
            }}
          >
            <EyeOutlined /> &nbsp; View More
          </div>
        </>
      );
      return (
        <Popover placement='leftTop' content={content} trigger='click'>
          <MoreOutlined />
        </Popover>
      );
    },
  },
];

export const getColumns = (
  onMoreClicked: (...args: any[]) => void,
  onDelete?: (data: any) => unknown
) => [
  {
    title: 'Product Id',
    dataIndex: 'productId',
    key: 'id',
    render: (_: any, data: any) => {
      return (
        <div title={data.productId}>
          {maskProductId(data.productId)}
        </div>
      );
    },
  },
  {
    title: 'Hub Id',
    dataIndex: 'hubId',
    key: 'hubId',
    render: (_: any, data: any) => {
      return (
        <div title={data.hubId}>
          {maskProductId(data.hubId)}
        </div>
      );
    },
  },
  {
    title: 'Product Name',
    dataIndex: 'productName',
    key: 'productName',
    render: (_: any, data: any) => {
      return (
        <div style={productImageCss}>
          <div style={productImageWrapperCss}>
            <ProductImage
              imgUrl={
                Array.isArray(data.image)
                  ? data.image && data.image[0]
                  : data.image
              }
            />
          </div>
          {data.productName}
        </div>
      );
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Stock',
    dataIndex: 'stock',
    key: 'stock',
  },
  {
    title: 'Unit Price',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
  },
  {
    title: 'Date Added',
    dataIndex: 'dateAdded',
    key: 'dateAdded',
    render: (_: unknown, data: any) => {
      return formatDate(data.dateAdded);
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_: unknown, data?: any) => {
      return (
        <div className={data.status}>
          {data.status === 'Approved' ? 'IN STOCK' : data.status}
        </div>
      );
    },
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (action: any, data: any) => {
      const content = (
        <>
          <NavLink to={`/manufacturers/products/${data.productId}`}>
            <EyeOutlined /> &nbsp; View More
          </NavLink>
          <div onClick={() => onDelete && onDelete(data)}>
            <DeleteOutlined /> &nbsp; Delete
          </div>
        </>
      );
      return (
        <Popover placement='leftTop' content={content} trigger='click'>
          <MoreOutlined />
        </Popover>
      );
    },
  },
];

export const dummyProducts = [
  {
    productId: 'CD90584',
    productName: 'Chicken and Duckling Mash',
    quantity: '50kg',
    unitPrice: '2,500',
    dateAdded: '14/05/2021',
    status: 'In Stock',
  },
  {
    productId: 'CD90584',
    productName: 'Chicken and Duckling Mash',
    quantity: '50kg',
    unitPrice: '2,500',
    dateAdded: '14/05/2021',
    status: 'In Stock',
  },
  {
    productId: 'CD90584',
    productName: 'Chicken and Duckling Mash',
    quantity: '50kg',
    unitPrice: '2,500',
    dateAdded: '14/05/2021',
    status: 'In Stock',
  },
  {
    productId: 'CD90584',
    productName: 'Chicken and Duckling Mash',
    quantity: '50kg',
    unitPrice: '2,500',
    dateAdded: '14/05/2021',
    status: 'In Stock',
  },
  {
    productId: 'CD90584',
    productName: 'Chicken and Duckling Mash',
    quantity: '50kg',
    unitPrice: '2,500',
    dateAdded: '14/05/2021',
    status: 'In Stock',
  },
  {
    productId: 'CD90584',
    productName: 'Chicken and Duckling Mash',
    quantity: '50kg',
    unitPrice: '2,500',
    dateAdded: '14/05/2021',
    status: 'In Stock',
  },
  {
    productId: 'CD90584',
    productName: 'Chicken and Duckling Mash',
    quantity: '50kg',
    unitPrice: '2,500',
    dateAdded: '14/05/2021',
    status: 'In Stock',
  },
];

export const mapProductsToTableData = (
  products: Array<NestedProducts | null>
) => {
  let mappedProducts = products.map((product) => {
    return {
      productId: product?.productId,
      productName: product?.name,
      quantity: product?.unit,
      stock: product?.quantity,
      unitPrice: product?.price,
      dateAdded: product?.creationDate,
      status: product?.status,
      image: product?.image,
      hubId: product?.hubId
    };
  });
  mappedProducts = _.orderBy(mappedProducts, ['dateAdded'], ['desc']);
  return mappedProducts;
};
