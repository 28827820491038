import { API, graphqlOperation } from "aws-amplify";
import { GetWardsQuery, CollectionCentreAddMilkDeliveryMutation, SearchFarmerQuery} from "../../../API";
import { getCollectionCentreByID, getCollectionCentres, getWards, searchFarmer } from "../../../graphql/queries";
import {collectionCentreAddMilkDelivery} from "../../../graphql/mutations";
import { addCognitoAuth } from "../../../helpers/helpers";
import { GraphQLResult } from '@aws-amplify/api';
import { useState } from "react";


export const useCollectionCentres = () => {

  const [collectionCentres, setCollectionCentres] = useState<any>([]);
  const [singleCollectionCentre, setSingleCollectionCentre] = useState<any>();
  const [wards, setWards] = useState<any>()
  const [farmers, setFarmers] = useState<any>([]);


  const getSingleCollectionCentre = async (id: string) => {
    try {
      const q = graphqlOperation(getCollectionCentreByID, {
        collectionCentreId: id
      });
      const { data } = (await API.graphql(
        addCognitoAuth(q)
      )) as GraphQLResult<any>;

      setSingleCollectionCentre(data?.getCollectionCentreByID?.data);
      // return data?.getBranches;
    } catch ({ message }) {
      return {
        data: null,
        message: message,
      };
    }
  }

  const handleGetWards = async () => {
    try {
      const query = graphqlOperation(getWards);
      const { data } = (await API.graphql(
        query
      )) as GraphQLResult<GetWardsQuery>;
      setWards(data?.getWards);
    } catch (error) {
      console.log(error);
    }
  };

  const getCollectionCentreList = async () => {
    try {
      const userObject = JSON.parse(localStorage.getItem('user') || '{}')
      const q = graphqlOperation(getCollectionCentres, { dmaId: userObject['custom:dmaId'] });
      const { data } = (await API.graphql(
        addCognitoAuth(q)
      )) as GraphQLResult<any>;

      setCollectionCentres(data?.getCollectionCentres?.data || []);
      localStorage.removeItem(`collectionCentre-${userObject['custom:dmaId']}`);
      localStorage.setItem(`collectionCentre-${userObject['custom:dmaId']}`, JSON.stringify(collectionCentres));

    } catch ({ message }) {
      return {
        data: null,
        message: message,
      };
    }
  };

  const getFarmers = async (param?: any) => {
    try {
        const userObject = JSON.parse(localStorage.getItem('user') || '{}')
        const query = graphqlOperation(searchFarmer, {
            page: 1,
            limit: 10,
            dmaId: userObject['custom:dmaId'],
            q: param || '',
        });
        const { data } = (await API.graphql(addCognitoAuth(query))) as GraphQLResult<SearchFarmerQuery>;
        setFarmers(data?.searchFarmer?.result);
    } catch (e) {
        console.log({e});
        setFarmers([]);
    }
};

  const addMilkCollection = async(payload: any) => {
    try{
      const mutation = graphqlOperation(collectionCentreAddMilkDelivery, {milkDelivery: payload});
      const { data } = (await API.graphql(
        addCognitoAuth(mutation)
      )) as GraphQLResult<CollectionCentreAddMilkDeliveryMutation>;
      return {
        data: data,
        message: data?.collectionCentreAddMilkDelivery?.message,
      };
    }catch({message}){
      return {
        data: null,
        message: message,
      };
    }
  }


  return {
    getCollectionCentreList,
    collectionCentres,
    setCollectionCentres,
    getSingleCollectionCentre,
    singleCollectionCentre,
    setSingleCollectionCentre,
    handleGetWards,
    wards,
    addMilkCollection,
    getFarmers,
    farmers
  };
}

