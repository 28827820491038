import React from "react";
import { GRAPHQL_AUTH_MODE, GraphQLOptions } from "@aws-amplify/api-graphql";
import { checkDmaPhoneNumber } from "../graphql/mutations";
import { API } from "aws-amplify";
// import axios, { AxiosRequestConfig } from "axios";

export const onChange: Function = (
  e: React.ChangeEvent<HTMLInputElement>,
  values: Record<string, unknown>,
  setValues: Function
) => {
  values[e.target.name] = e.target.value;
  setValues({ ...values });
  return values;
};

export const formatNumber = (
  number: string,
  setNumber: (number: string) => void
): void => {
  if (number.length <= 10) {
    setNumber(number);
  } else {
    // message.error("Phone number can't be more than 10 digits");
  }
};

export const checkNumberIsComplete = (
  number: string,
  setMessage: (message: string) => void
): any => {
  if (number.length < 10) {
    setMessage("Phone number can't be less than 10 digits");
    return false;
  } else {
    return true;
  }
};

export const formatToIntl = (number: string, omitPlus = false): string => {
  const getLastDigits = number.slice(number.length - 9);
  return `${omitPlus ? "" : "+"}254${getLastDigits}`;
};

export const validateEmail = (email: string) => {
  return new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(email.toLowerCase());
};

export const validatePhoneNumber = (phone: string) : boolean => {
  return phone.length >= 10 && new RegExp('^[0-9]+$').test(phone);
}

export const getInitials = (name: string) => {
  if (name.includes(" ")) {
    const nameArr = name.split(" ");
    return `${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`;
  } else {
    return name.charAt(0);
  }
};

type QLOperation = { query: any; variables?: any };

export const addCognitoAuth = (q: QLOperation): GraphQLOptions => ({
  ...q,
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
});


//check if farmer last name is null 

export const CheckForNull: (name:string)=> string = (name: string) => {
  let farmerName;
  const splitName = name.split(" ")
  if(splitName[1] === "null"){
    farmerName = splitName[0]
  }else{
    farmerName = name
  }
  return farmerName;
}


function graphqlOperation(checkDmaPhoneNumber: string, arg1: { phoneNumber: string; }) {
  throw new Error("Function not implemented.");
}
  //function to get Image from storage, this function takes in an arr of image link from the fetchProducts Response

  // export const getImages = async (images: (string | null)[] | null | undefined)=>{
  //     for(let i = 0; i < images!.length; i++) {
  //      await  Storage.get(images![i]!).then(res =>{
  //        console.log({res})
  //         return res
  //         })
  //       }

  // }
