import { API, graphqlOperation } from "aws-amplify";
import {
  ApproveMilkDeliveryMutation,
  GetWardsQuery,
  DmaEnableTransporterMutation,
  DmaDisableTransporterMutation,
  SearchUserQuery
} from "../../../API";
import {
  getTransporterById,
  getDmaTransporters,
  getWards,
  getDeliveryApprovals,
  searchUser
} from "../../../graphql/queries";
import { addCognitoAuth } from "../../../helpers/helpers";
import { GraphQLResult } from '@aws-amplify/api';
import { useState } from "react";
import moment, { Moment } from 'moment';
import { approveMilkDelivery, dmaEnableTransporter, dmaDisableTransporter } from "../../../graphql/mutations";


export const useTransporters = () => {

  const [transporters, setTransporters] = useState<any>([]);
  const [deliveryApproval, setDeliveryApproval] = useState<any>([]);
  const [singleTransporter, setSingleTransporter] = useState<any>();
  const [wards, setWards] = useState<any>();
  const [date, setDate] = useState<Moment | null>(moment(new Date()));
  const [selectedTransporterId, setSelectedTransporterId] = useState("");
  const [collectionCentreId, setCollectionCentreId] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [rejectedQuantity, setRejectedQuantity] = useState("");
  const [approvedQuantity, setApprovedQuantity] = useState("");
  const [varianceQuantity, setVarianceQuantity] = useState("");
  const [allowed, setAllowed] = useState('');
  const [deliveryTime, setDeliveryTime] = useState("");
  const [deliveries, setDeliveries] = useState<any>([]);
  const [containerNumbers, setContainerNumbers] = useState<any>([]);
  const [inputs, setInputs] = useState([{
    containerNumber: ""
  }]);

  
  const getSingleTransporter = async (id: string) => {
    try {
      const userObject = JSON.parse(localStorage.getItem('user') || '{}');
      const q = graphqlOperation(getTransporterById, {
        transporterId: id
      });
      const { data } = (await API.graphql(
        addCognitoAuth(q)
      )) as GraphQLResult<any>;

      setSingleTransporter(data?.getTransporterById?.data);
      // return data?.getBranches;
    } catch ({ message }) {
      return {
        data: null,
        message: message,
      };
    }
  }

  const handleGetWards = async () => {
    try {
      const query = graphqlOperation(getWards);
      const { data } = (await API.graphql(
        query
      )) as GraphQLResult<GetWardsQuery>;
      setWards(data?.getWards);
    } catch (error) {
      console.log(error);
    }
  };

  const getTransportersList = async () => {
    try {
      const userObject = JSON.parse(localStorage.getItem('user') || '{}')
      const q = graphqlOperation(searchUser, { dmaId: userObject['custom:dmaId'], group: 'DMA_TRANSPORTER' });
      const { data } = (await API.graphql(addCognitoAuth(q))) as GraphQLResult<SearchUserQuery>;

      setTransporters(data?.searchUser?.result);
      localStorage.removeItem(`transporter-${userObject['custom:dmaId']}`);
      localStorage.setItem(`transporter-${userObject['custom:dmaId']}`, JSON.stringify(transporters));

    } catch ({ message }) {
      return {
        data: null,
        message: message,
      };
    }
  };

  const getDeliveryApprovalList = async (transporterId?: any, collectionCenterId?: any) => {
    try {
      const userObject = JSON.parse(localStorage.getItem('user') || '{}')
      const q = graphqlOperation(getDeliveryApprovals, { transporterId, collectionCenterId: userObject['custom:collectionCentreId'] || collectionCenterId });
      const { data } = (await API.graphql(
        addCognitoAuth(q)
      )) as GraphQLResult<any>;

      setDeliveryApproval(JSON.parse(data?.getDeliveryApprovals?.data)||[]);

    } catch ({ message }) {
      return {
        data: null,
        message: message,
      };
    }
  };

  const approveDelivery = async (payload: any) =>{
    try{
      const mutation = graphqlOperation(approveMilkDelivery, {milkDeliveries: payload});
      const { data } = (await API.graphql(
        addCognitoAuth(mutation)
      )) as GraphQLResult<ApproveMilkDeliveryMutation>;
      return {
        data: data,
        message: data?.approveMilkDelivery?.message,
      };
    }catch({message}){
      console.log(message);
      return {
        data: null,
        message: `${message}` || "An error has occurred",
      };
    }
  }

  const activateTransporter = async(id: any) => {
    try{
      const mutation = graphqlOperation(dmaEnableTransporter, {username: id});
      const { data } = (await API.graphql(
        addCognitoAuth(mutation)
      )) as GraphQLResult<DmaEnableTransporterMutation>;
      const resp = JSON.parse(data?.dmaEnableTransporter || '{}');
      console.log({resp});
      return {
        data: resp.data|| null,
        code: Number(resp.code),
        message: resp.message,
      };
    }catch({message}){
      console.log(message);
      return {
        data: null,
        code: 500,
        message: `${message}` || "An error has occurred",
      };
    }
  }

  const deactivateTransporter = async(id: any) => {
    try{
      const mutation = graphqlOperation(dmaDisableTransporter, {username: id});
      const { data } = (await API.graphql(
        addCognitoAuth(mutation)
      )) as GraphQLResult<DmaDisableTransporterMutation>;
      const resp = JSON.parse(data?.dmaDisableTransporter || '{}');
      console.log({resp});
      return {
        data: resp.data|| null,
        code: resp.code,
        message: resp.message,
      };
    }catch({message}){
      console.log(message);
      return {
        data: null,
        code: 500,
        message: `${message}` || "An error has occurred",
      };
    }
  }


  return {
    getTransportersList,
    getDeliveryApprovalList,
    setDeliveryApproval,
    deliveryApproval,
    transporters,
    setTransporters,
    getSingleTransporter,
    singleTransporter,
    setSingleTransporter,
    handleGetWards,
    activateTransporter,
    deactivateTransporter,
    wards,
    date, 
    setDate,
    selectedTransporterId, 
    setSelectedTransporterId,
    collectionCentreId, 
    setCollectionCentreId,
    isSearching, 
    setIsSearching,
    allowed, 
    setAllowed,
    quantity, 
    setQuantity,
    rejectedQuantity, 
    setRejectedQuantity,
    approvedQuantity, 
    setApprovedQuantity,
    varianceQuantity, 
    setVarianceQuantity,
    deliveryTime, 
    setDeliveryTime,
    inputs, 
    setInputs,
    deliveries, 
    setDeliveries,
    containerNumbers, 
    setContainerNumbers,
    approveDelivery
  };
}

