// import { CopyOutlined } from '@ant-design/icons';
import { FileProtectOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import FileIcon from '../../../assets/upload document (1).svg';

// import './fileUpload.css';
// import logo from '../../assets/image.png';

type ProductUploadProps = {
  setFiles: (files: File[]) => void;
  files: File[];
  handleUpload: () => void;
  loading?: boolean;
  disabled?: boolean
};

const baseStyle = {
  flex: 1,
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#94979b',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const ProductUpload: React.FC<ProductUploadProps> = (props) => {
  const { files, setFiles, handleUpload, loading, disabled } = props;

  const onDrop: (acceptedFiles: File[]) => void = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    []
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    accept: ['.csv', '.xlsx', '.xls'],
  });

  const uploadedFiles = acceptedFiles.map((file: any) => (
    <li style={{ color: 'black' }} key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const onClickRemove: (id: string) => void = (name) => {
    const newFiles = files.filter((file) => file.name !== name);
    setFiles(newFiles);
  };

  return (
    <section className='container padding-0'>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} multiple={false} />
        <div className="text-align-center mb-5 mt-2">
          <img src={FileIcon} alt="icon"/>
        </div>

        <div>
          <p
            style={{
              textAlign: 'center',
              color: '#43434a',
              fontSize: '16px',
              fontWeight: 500,
              marginTop: '10px',
            }}
          >
            Upload your document
          </p>
        </div>
        <p style={{ textAlign: 'center', color: '#43434a' }}>
          Drag or drop or <span className="primary-color">Browse</span> your files here
        </p>
        <aside>
          {uploadedFiles.length > 0 && (
            <ol className='dropped-files'>
              {isDragActive ||
                files.map((f) => {
                  return (
                    <li
                      style={{ color: 'black' }}
                      key={`${f.name}image`}
                      className='d-flex justify-content-between'
                    >
                      <Col md={10}>{f.name} </Col>
                      <Col
                        md={2}
                        className='red-color ml-3 text-align-center'
                        style={{ cursor: 'pointer' }}
                        onClick={() => onClickRemove(f.name)}
                      >
                        x
                      </Col>
                    </li>
                  );
                })}
            </ol>
          )}
        </aside>
      </div>
      <button
      disabled={loading || disabled}
        className='primary-btn col-12 fw-500 mt-5'
        type='submit'
        onClick={handleUpload}
      >
        {loading? "Uploading..." : "Upload"}
      </button>
      <aside></aside>
    </section>
  );
};
