import  { FC, useState } from 'react';
import { Col, Nav, Navbar } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { NavLink } from '../layouts.model';
import { useNavItems } from '../useNavItems';
import { NavItem } from './NavItem';
import bell from '../../assets/SVG/bell.svg';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import './Header.css';
import { CaretDownOutlined } from '@ant-design/icons';
import { LogoutButton } from '../../components/LogoutButton';

// const getLoggedInUser = 


const defaultLinks = (history: any, user: any) => {
  return [
    {
      title: 'Notifications',
      icon: '',
      path: '/home',
      key: uuid(),
      isSelected: true,
      render: (item: any) => (
        <div className="d-flex my-auto h-100">
          &nbsp;
          <img src={bell} alt={item.title} />
        </div>
      ),
    },
    {
      title: 'Avatar',
      icon: '',
      path: '',
      key: uuid(),
      render: () => <Avatar />,
    },
    {
      title: 'Name and Role',
      icon: '',
      path: '',
      key: uuid(),
      render: (item: any) => (
        <div
          className="d-flex flex-column flex-grow-1 flex-shrink-0"
          style={{ width: '4rem' }}
          title={item.title}
        >
          <h5 className="primary-color" style={{ fontSize: '80%' }}>
          {user.name}
          </h5>
          <h6 style={{ fontSize: '80%' }}>{user["custom:group"]}</h6>
        </div>
      ),
    },
    {
      title: 'More',
      icon: '',
      path: '',
      key: uuid(),
      type: 'button',
      render: (item: any) => {
        const menu = (
          <Menu>
              <Menu.Item onClick={()=> history.push("/manufacturers/profile")} key="0" icon={<UserOutlined />}>
                Profile
              </Menu.Item>
              <LogoutButton/>
          </Menu>
        );

        return (
          <Dropdown overlay={menu}>
            <CaretDownOutlined title={item.title} style={{ color: 'black' }} />
          </Dropdown>
        );
      },
    },
  ];
}


export const Header: FC = () => {
  let history = useHistory();
  const [user] = useState(JSON.parse(localStorage.getItem('user') || '{}'))
  const [links, selectLink] = useNavItems(defaultLinks(history, user) as NavLink[]);

  const openSidebar = () => {
    const sidebar = document.getElementById("sidenav")!;
    sidebar.classList.add('opened')
  }

  return (
    <Col
      as={Navbar}
      defaultactivekey="/home"
      className="position-relative do-not-print justify-content-between align-items-center border idp-nav p-0 m-0 enhanced"
    >
      <div className="col-md-4 d-flex">
        <button type="button" className="toggle-sidebar" onClick={() => openSidebar()}>
          <i className="mdi mdi-menu"></i>
        </button>

        <div className="flex-grow-1">
          <div className="form-group header-search">
            <input type="text" className="form-control" placeholder="Search"/>
          </div>
        </div>
      </div>

      {/* {(path === '/' || path === '/home') && (
        <div className="w-fit-content align-self-center position-absolute main-heading">
          <h2>Welcome to your dashboard</h2>
        </div>
      )} */}

      <Nav className="h-100 d-flex flex-direction-row align-items-center">
        {links.map((link) => (
          <NavItem key={link.key} item={link} onSelect={selectLink} />
        ))}
      </Nav>
    </Col>
  );
};
