import React, { FC, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AuthRouteProps } from './layouts.model';
import { getCurrentSession, logout } from '../redux/actions/authActions';

const PrivateRoute: FC<AuthRouteProps> = ({
  component: Component,
  auth,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [hasCheckedSession, setHasCheckedSession] = useState(false);

  useEffect(() => {
    if (!hasCheckedSession && !auth.isAuthenticated) {
      dispatch(getCurrentSession());
      setHasCheckedSession(true);
    }
  }, [dispatch, auth.isAuthenticated, hasCheckedSession]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated && auth.isManufacturer) {
          return <Redirect to="/manufacturers" />;
        } else if (auth.isAuthenticated) {
          return <Redirect to="/dma" />;
        } 
        else {
          //enable this line below to make route protected
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

const mapStateToProps = ({ auth }: AuthRouteProps) => ({
  auth,
});

export default connect(mapStateToProps)(PrivateRoute);
