import {
  BellOutlined,
  CaretDownOutlined,
  DownOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

import { Badge, Dropdown, Menu, Drawer, Divider } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import logo from "../../../assets/LOGO/logo.png";
import { LogoutButton } from "../../../components/LogoutButton";
import { KYCButton } from "../../../components/KYCButton";
import { ProfileButton } from "../../../components/ProfileButton";
import { AuthRouteProps } from "../../../layouts/layouts.model";
import "../dma.css";
import { useHistory } from "react-router-dom";
import { logout } from "../../../redux/actions/authActions";
import { useCollectionCentres } from "../../cooperative/collectionCentres/useCollectionCentres";
import { useTransporters } from "../../cooperative/transporter/useTransporters";
import "../responsiveness.css";
import { isCooperative, isCollectionCentreUser, isCooperativeMulti, isMainBranchUser } from "../../../helpers/permissions";
import { useSignIn } from '../../auth/ManufacturerSignIn/useSignIn';
import { Auth } from "aws-amplify";
import { LOGOUT } from "../../../redux/actionTypes";
import { isScreenOnMobile } from "../../../utills/screen";

const DmaHeader: React.FC<AuthRouteProps> = (props) => {
  
  const user = props.auth.user;
  const cart = props.auth.cart;
  const history = useHistory();
  const {
    dispatch,
    setError
  } = useSignIn();
  const [collectionCentresList, setCollectionCentresList] = useState([]);
  const [collectionCenter, setCollectionCenter] = useState<any>({});
  const [transportersList, setTransportersList] = useState([]);

  const userObject = JSON.parse(localStorage.getItem("user") || "{}");

  const { getCollectionCentreList, collectionCentres } = useCollectionCentres();

  const { getTransportersList, transporters } = useTransporters();

  const getBranchDetails = JSON.parse(localStorage.getItem("dma") || "{}");

  useEffect(() => {
    if(isCooperativeMulti()){
      getCollectionCentreList();
      getTransportersList();
    }
  }, []);

  useEffect(() => {
    const data = collectionCentres ||
    JSON.parse(
      localStorage.getItem(
        `collectionCentre-${userObject["custom:dmaId"]}`
      ) || "[]"
    );
    setCollectionCentresList(data);
    setCollectionCenter(data?.find((d: any) => d.collectionCentreId === userObject["custom:collectionCentreId"]));
  }, [collectionCentres]);

  useEffect(() => {
    setTransportersList(
      transporters ||
        JSON.parse(
          localStorage.getItem(`transporter-${userObject["custom:dmaId"]}`) ||
            "[]"
        )
    );
  }, [transporters]);

  const [visible, setVisible] = useState(false);

  const branchDetails = getBranchDetails?.data?.dmaBranchDetail?.find((branch: any) => branch.branchId === getBranchDetails?.data?.userDetail?.branchId);

  const showDrawer = () => {
    if (isScreenOnMobile()) {
      setVisible(!visible);
    }
  };
  const onClose = () => {
    setVisible(false);
  };

  const redirectRouteAndCloseDrawer = (routeName: string) => {
    history.push(routeName);
    onClose()
  }

  const branches = (
    <Menu>
      <Menu.Item key={1}>
        <div onClick={() => redirectRouteAndCloseDrawer(`/branches`)}>ALL</div>
      </Menu.Item>
      {getBranchDetails && getBranchDetails?.data?.dmaBranchDetail?.map((p: any, index: number) => {
        if (p?.isHQ) {
          return (
            <Menu.Item key={index + 2}>
              <div
                onClick={() => redirectRouteAndCloseDrawer(`/branches/${p?.branchId}/details`)}>
                {p.branchName || "N/A"}
              </div>
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );

  const agrovetsMenu = (
    <Menu>
      <Menu.Item key={1}>
        <div onClick={() => redirectRouteAndCloseDrawer(`/agrovets`)}>ALL</div>
      </Menu.Item>
      {/* {getBranchDetails?.data?.dmaBranchDetail?.map((p: any, index: number) => {
        if (p.isHQ) {
          return (
            <Menu.Item key={index + 2}>
              <div
                onClick={() => history.push(`/branches/${p.branchId}/details`)}
              >
                {p.branchName || "N/A"}
              </div>
            </Menu.Item>
          );
        }
      })} */}
    </Menu>
  );

  const collectionCentresMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key={1}>
          <div onClick={() => redirectRouteAndCloseDrawer(`/collection-centres`)}>ALL</div>
        </Menu.Item>
        {collectionCentresList?.map((p: any, index: number) => {
          return (
            <Menu.Item key={index + 2}>
              <div
                onClick={() => redirectRouteAndCloseDrawer(`/collection-centres/${p.collectionCentreId}/details`)}
              >
                {p.centreName || "N/A"}
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    ),
    [collectionCentresList, history]
  );

  const transportersMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key={1}>
          <div onClick={() => redirectRouteAndCloseDrawer(`/transporters`)}>ALL</div>
        </Menu.Item>
        {transportersList?.map((p: any, index: number) => {
          return (
            <Menu.Item key={index + 2}>
              <div
                onClick={() => redirectRouteAndCloseDrawer(`/transporters/${p.username}/details`)}
              >
                {p.name || "N/A"}
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    ),
    [transportersList, history]
  );

  const logout = (
    <Menu>
      <Menu.Item key="3">
        <ProfileButton />
      </Menu.Item>
      <Menu.Item key="2">
        <KYCButton />
      </Menu.Item>
      <Menu.Item key="1">
        <LogoutButton />
      </Menu.Item>
    </Menu>
  );

  const FarmerOrdersMenu = (
    <Menu>
      <Menu.Item key='0'>
        <div onClick={() => redirectRouteAndCloseDrawer('/dma/farmersorders')}>List All</div>
      </Menu.Item>
      <Menu.Item key='1'>
        <div onClick={() => redirectRouteAndCloseDrawer('/dma/farmers/orders')}>
          Create New
        </div>
      </Menu.Item>
    </Menu>
  );
  const StockMenu = (
    <Menu>
      <Menu.Item key='0'>
        <div onClick={() => redirectRouteAndCloseDrawer('/dma/stock')}>View Stocks</div>
      </Menu.Item>
      { isCooperativeMulti() && 
      <Menu.Item key='1'>
        <div onClick={() => redirectRouteAndCloseDrawer('/dma/stock/requests')}>
          Stock Requests
        </div>
      </Menu.Item> }
    </Menu>
  );

  const cooperativelinks = (
    <>
      <ul
        style={{ display: "flex", justifyContent: 'flex-end' }}
        className="container li-display margin-top-5px"
      >
        <li className="li-style m-display-none pointer"
                onClick={() => history.push("/dma/cart")}>
          <div className="d-flex align-items-center">
            <Badge count={cart?.cartItems?.length}>
              <ShoppingCartOutlined
                style={{ color: "#39b54a", fontSize: "18px" }}
              />
            </Badge>
            &nbsp;
            {/* <BellOutlined
              style={{
                color: "#39b54a",
                marginLeft: "1.5rem",
                fontSize: "18px",
              }}
            /> */}
          </div>
        </li>
        <Dropdown overlay={logout}>
          <li className="li-style  m-display-none">
            <div className="d-flex align-items-center">
              &nbsp;
              <div className="li-text mr-2 ml-2">{user?.name}</div>
              <CaretDownOutlined />
            </div>
          </li>
        </Dropdown>
      </ul>
      <Divider type="horizontal" className="show-mobile header-divider" />
      <ul style={{ display: 'flex', justifyContent: 'flex-end' }} className="container li-text li-display sidebar-container">
        <NavLink to={"/dma/dashboard"}>
          <li style={{ color: "#222222" }} className="li-style" onClick={showDrawer}>
            Dashboard
          </li>
        </NavLink>
        {isCooperativeMulti() && isMainBranchUser()  ? (
          <Dropdown overlay={branches}>
            <li className="li-style ">
              <div className="d-flex align-items-center">
                <div className="li-text mr-2 ml-2 branch-txt">Branches</div>
                <CaretDownOutlined />
              </div>
            </li>
          </Dropdown>
        ) : !isMainBranchUser() ? (
          <NavLink to={`/branches/${branchDetails?.branchId}/details`}>
          <li style={{ color: "#222222" }} className="li-style">
            {branchDetails?.branchName}
          </li>
        </NavLink>
      ) : (
        <></>
      )}

      {isCooperative() && isMainBranchUser() && (
            <Dropdown overlay={agrovetsMenu}>
            <li className="li-style ">
              <div className="d-flex align-items-center">
                <div className="li-text mr-2 ml-2 agrovet-txt">Agrovets</div>
                <CaretDownOutlined />
              </div>
            </li>
          </Dropdown>
        )}

        {isCooperative() ? (
          <Dropdown overlay={collectionCentresMenu}>
            <li className="li-style ">
              <div className="d-flex align-items-center gap-1 nav-item-long-text">
                <div>Collection Centres</div>
                <CaretDownOutlined />
              </div>
            </li>
          </Dropdown>
        ) : isCollectionCentreUser() ? (
            <NavLink to={`/collection-centres/${collectionCenter?.collectionCentreId}/details`}>
            <li style={{ color: "#222222" }} className="li-style">
              {collectionCenter?.centreName}
            </li>
          </NavLink>
        ) : (
          <></>
        )}

        {isCooperative() || isCollectionCentreUser() ? (
          <Dropdown overlay={transportersMenu}>
            <li className="li-style ">
              <div className="d-flex align-items-center gap-1">
                <div>Transporters</div>
                <CaretDownOutlined />
              </div>
            </li>
          </Dropdown>
        ) : (
          <></>
        )}
        
        {isCooperative() && (
          <NavLink to={"/cooperative/farmers"}>
          <li style={{ color: "#222222" }} className="li-style">
            Farmers
          </li>
        </NavLink>
        )}

      

        <NavLink to={"/dma/products"}>
          <li style={{ color: "#222222" }} className="li-style" onClick={showDrawer}>
            Products
          </li>
        </NavLink>
        <NavLink to={"/dma/my-orders"}>
          <li style={{ color: "#222222" }} className="li-style" onClick={showDrawer}>
            Orders
          </li>
        </NavLink>
        <Dropdown overlay={StockMenu}>
            <li className="li-style ">
              <div className="d-flex align-items-center gap-1">
                <div>Stocks</div>
                <CaretDownOutlined />
              </div>
            </li>
          </Dropdown>
        <li className="li-style">
          <Dropdown overlay={FarmerOrdersMenu} placement="topRight" arrow>
            <div className="d-flex align-items-center gap-1 nav-item-long-text">
              <div>Farmers Orders</div> <DownOutlined />
            </div>
          </Dropdown>
        </li>
        {/* <Divider className="show-mobile" /> */}
        <li className="li-style show-mobile">
          <ProfileButton handleCloseDrawer={showDrawer} />
        </li>
        <li className="li-style kyc-details show-mobile">
        <KYCButton />
        </li>
        <li className="li-style show-mobile" onClick={showDrawer}>
        <LogoutButton />
        </li>
        
            
      </ul>
    </>
  );

  return (
    <div className="dma-header-position">
      <div className="d-flex m-align-center dma-header-holder do-not-print m-space-between">
        <Col md={isCooperativeMulti() || isCollectionCentreUser() ? 3 : 8} className="do-not-print pull-right">
          <NavLink to={"/dma"}>
            <img style={{ float: 'left' }} src={logo} alt="logo" className="header-logo" />
          </NavLink>
        </Col>
        {/* <Col md={2} className='do-not-print m-display-none'>
          <SearchInput /> 
        </Col> */}

        <Col
          md={4}
          className="pull-left show-mobile margin-top-5px justify-content-around wrapper"
        >
          {/* <li className="li-style">
            <BellOutlined style={{ color: "#39b54a", fontSize: "18px" }} />
          </li> */}
          <li className="li-style pointer" onClick={() => history.push("/dma/cart")}>
            <Badge count={cart?.cartItems?.length}>
              <ShoppingCartOutlined
                style={{ color: "#39b54a", fontSize: "18px" }}
              />
            </Badge>
          </li>
          <MenuOutlined onClick={showDrawer} />
        </Col>
        <Col
          md={isCooperativeMulti() || isCollectionCentreUser() ? 9: 4}
          className="fw-500 do-not-print m-display-none"
        >
          {cooperativelinks}
        </Col>
        <div className="m-display-none">
          <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            <Col md={12} className="fullheight">
              {cooperativelinks}
            </Col>
            <Divider className="header-divider" />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }: AuthRouteProps) => ({
  auth,
});

export default withRouter(connect(mapStateToProps)(DmaHeader));