import { PayloadAction } from "@reduxjs/toolkit";
import { SET_CONFIRMATION_PAYLOAD, CLEAR_CONFIRMATION_PAYLOAD, SET_APPROVE_DATA, CLEAR_APPROVE_DATA } from "../actionTypes";

const initialState = {
  data: {},
  approvalData: {}
};

export const transportReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case CLEAR_CONFIRMATION_PAYLOAD:
      return { ...state, data: {} };
    case SET_CONFIRMATION_PAYLOAD:
      return { ...state, data: action.payload };
    case SET_APPROVE_DATA:
      return { ...state, approvalData: action.payload };
    case CLEAR_APPROVE_DATA:
      return { ...state, approvalData: {} };
    default:
      return state;
  }
};
