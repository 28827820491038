import logo from "../assets/LOGO/logo-white.png";
export const Footer = () => {
  return (
    <div className="d-footer d-flex align-items-center justify-content-between dma-bg">
      <img src={logo} alt={"logo"} width={72} height={45} />
      <ul className="footer-grid-auto">
        <li className="li-style-none">© 2020 {new Date().getFullYear() !== 2020 ? `- ${new Date().getFullYear()}` : '' } · All rights reserved</li>
        <li className="li-style-none">Privacy Policy</li>
        <li className="li-style-none">Terms of Service</li>
      </ul>
    </div>
  );
};