import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { PageLoader } from '../components/Loaders';
import PrivateRoute from '../layouts/PrivateRoute';
import AuthRoute from '../layouts/AuthRoute';
import DmaPublicRoute from '../layouts/DmaRoute';
import DmaPrivateRoute from '../layouts/DmaPrivateRoute';

import ManufacturerRoute from '../layouts/ManufacturerRoute';
import DMAOrderDetails from '../pages/DMA/orders/DmaOrderDetails';
import ProductUploadList from '../pages/manufacturers/products/ProductsUpload';
import CooperativeMultiPrivateRoute from '../layouts/CooperativeMultiPrivateRoute';
import CooperativePrivateRoute from '../layouts/CooperativePrivateRoute';
import CollectionCentreRoute from '../layouts/CollectionCentreRoute';
import CooperativeMainBranchPrivateRoute from '../layouts/CooperativeMainBranchPrivateRoute';
import Agrovets from '../pages/cooperative/agrovets/Agrovets';
import AddAgrovet from '../pages/cooperative/agrovets/AddAgrovet';
import { ToastContainer } from 'react-toastify';
import DmaStockUpdateHistory from "../pages/DMA/dmaStock/StockUpdateHistory";

// create Loadable pages
const Home = lazy(() => import('../pages/home/Home'));
const Login = lazy(() => import('../pages/auth/SignIn/SignIn'));
const SalesDetails = lazy(() => import('../pages/sales/SalesDetails'));
const Register = lazy(() => import('../pages/auth/register/Register'));
const RegistrationLanding = lazy(() => import('../pages/auth/register/RegistrationLandingPage'));
const AwaitApproval = lazy(()=> import('../pages/auth/register/AwaitApproval'))
const KYCForm = lazy(()=> import('../pages/auth/register/KYCForm'))
const Verify = lazy(()=> import('../pages/auth/register/Verify'))
const ManufacturerSignIn = lazy(
  () => import('../pages/auth/ManufacturerSignIn/ManufacturerSignIn')
);
const ManufacturersDashboard = lazy(() => import('../pages/manufacturers'));
const NewProduct = lazy(
  () => import('../pages/manufacturers/products/NewProduct')
);
const ManufacturersProducts = lazy(
  () => import('../pages/manufacturers/products/Products')
);
const Sales = lazy(() => import('../pages/sales/Sales'));
const ProductDetailsPage = lazy(
  () => import('../pages/manufacturers/products/ProductDetails/ProductDetails')
);

const FarmersOrders = lazy(() => import('../pages/DMA/orders/FarmerOrders'));
const CreateFarmerOrder = lazy(() => import('../pages/DMA/farmers/Farmers'));
const PaymentPage = lazy(() => import('../pages/DMA/farmers/PaymentPage'));
const PaymentConfimationPage = lazy(() => import('../pages/DMA/farmers/PaymentConfimationPage'));

const FarmerOrderDetails = lazy(
  () => import('../pages/DMA/orders/FarmerOrderDetails')
);
const ManufacturerProfile = lazy(
  () => import('../pages/manufacturers/profile/ManufacturerProfile')
);
const Hubs = lazy(() => import("../pages/manufacturers/hubs"))
const AddHub = lazy(() => import("../pages/manufacturers/hubs/AddHub"))
const HubDetails = lazy(() => import("../pages/manufacturers/hubs/HubDetails"))

const LandingPage = lazy(() => import('../pages/DMA/LandingPage'));
const Products = lazy(() => import('../pages/DMA/products/Product'));
const ViewProduct = lazy(() => import('../pages/DMA/products/ViewProduct'));
const DmaStock = lazy(() => import('../pages/DMA/dmaStock/index'));
const AddDmaStock = lazy(() => import('../pages/DMA/dmaStock/AddStock'));
const EditDmaStock = lazy(() => import('../pages/DMA/dmaStock/UpdateStock'));
const RequestStock = lazy(() => import('../pages/DMA/dmaStock/stockRequest/index'));
const RequestStockDetails = lazy(() => import('../pages/DMA/dmaStock/stockRequest/Details'));
const DmaCart = lazy(() => import('../pages/DMA/products/cart/Cart'));
const Checkout = lazy(() => import('../pages/DMA/products/checkout/Checkout'));
const Dashboard = lazy(() => import('../pages/DMA/dashboard/Dashboard'));
const ConfirmStatus = lazy(
  () => import('../pages/DMA/products/checkout/ConfirmStatus')
);
const DmaOrders = lazy(() => import('../pages/DMA/orders/DmaOrders'));
const Report = lazy(() => import('../pages/reports/Report'));
const BulkUpload = lazy(()=> import('../pages/DMA/bulkUpload/BulkUpload'));

const CooperativeBranchList = lazy(
  () => import('../pages/cooperative/branch/BranchList')
);
const CooperativeAddBranch = lazy(() => import('../pages/cooperative/branch/AddBranch'));
const CooperativeBranchDetails = lazy(
  () => import('../pages/cooperative/branch/BranchDetails')
);

const CooperativeAddUser = lazy(
  () => import('../pages/cooperative/users/AddUser')
);

const CooperativeCollectionCentreList = lazy(
  () => import('../pages/cooperative/collectionCentres/collectionsCentreList')
);

const CooperativeAddCollectionCentre = lazy(
  () => import('../pages/cooperative/collectionCentres/AddCollectionCentre')
);

const CooperativeCollectionCentreDetails = lazy(
  () => import('../pages/cooperative/collectionCentres/CollectionCentreDetails')
);

const CooperativeAddCollectionCentresUser = lazy(
  () => import('../pages/cooperative/collectionCentres/users/AddUser')
);

const CooperativeTransportersList = lazy(
  () => import('../pages/cooperative/transporter/TransporterList')
);

const CooperativeAddTransporter = lazy(
  () => import('../pages/cooperative/transporter/AddTransporter')
);

const CooperativeTransporterDetails = lazy(
  () => import('../pages/cooperative/transporter/TransporterDetails')
);

const CooperativeFarmersList = lazy(
  () => import('../pages/cooperative/farmers/FarmerList')
);

const CooperativeFarmersDetails = lazy(
  () => import('../pages/cooperative/farmers/FarmerDetails')
);

const CooperativeAddFarmers = lazy(
  () => import('../pages/cooperative/farmers/AddFarmer')
);

const CooperativeAddFarmerPayment= lazy(
  () => import('../pages/cooperative/farmers/AddFarmerPayment')
);

const CooperativeFarmerBulkUpload = lazy(
  () => import('../pages/cooperative/farmers/FarmerBulkUpload')
);

const DMAProfile = lazy(
  () => import('../pages/DMA/profile/DMAProfile')
);

const Routes = () => (
  // eslint-disable-next-line no-undef
  <Router basename={process.env.PUBLIC_URL}>
    <Suspense fallback={<PageLoader />}>
      {/* <AlertWrapper ref={alertRef => ((window as any).alertRef = alertRef)} /> */}
      <ToastContainer />
      <Switch>
        <PrivateRoute exact path='/' component={Home} />
        <AuthRoute exact path='/dma-login' component={Login} />
        <PrivateRoute exact path='/home' component={Home} />
        <ManufacturerRoute
          exact
          path='/manufacturers'
          component={ManufacturersDashboard}
        />
        <ManufacturerRoute
          exact
          path='/manufacturers/sales'
          component={Sales}
        />
        <ManufacturerRoute exact path='/reports' component={Report} />
        <ManufacturerRoute
          exact
          path='/manufacturers/sales/:id'
          component={SalesDetails}
        />
        <ManufacturerRoute
          exact
          path='/manufacturers/products/upload'
          component={ProductUploadList}
        />
        <ManufacturerRoute
          exact
          path='/manufacturers/products'
          component={ManufacturersProducts}
        />
        <ManufacturerRoute
          exact
          path='/manufacturers/products/upload-list'
          component={ProductUploadList}
        />
        <ManufacturerRoute
          exact
          path='/manufacturers/profile'
          component={ManufacturerProfile}
        />
        <ManufacturerRoute
          exact
          path='/manufacturers/products/add-new'
          component={NewProduct}
        />
        <ManufacturerRoute
          exact
          path='/manufacturers/products/:id'
          component={ProductDetailsPage}
        />
        <ManufacturerRoute
          exact
          path='/manufacturers/dashboard'
          component={ManufacturersDashboard}
        />
        <ManufacturerRoute
            exact
            path='/manufacturers/hubs'
            component={Hubs}
        />
        <ManufacturerRoute
            exact
            path='/manufacturers/hubs/add'
            component={AddHub}
        />
        <ManufacturerRoute
            exact
            path='/manufacturers/hubs/:id'
            component={HubDetails}
        />
        {/* can't access them when you are logged in */}
        <AuthRoute component={RegistrationLanding} exact path='/register' />
        <AuthRoute
          component={AwaitApproval}
          exact
          path='/complete-registration'
        />
        <AuthRoute component={KYCForm} exact path='/upload-kyc' />
        <AuthRoute component={Register} exact path='/register-form' />
        <AuthRoute component={Verify} exact path='/dma-verification' />
        <AuthRoute component={ManufacturerSignIn} exact path='/login' />
        {/* can only access them when you are logged in */}
        {/* public route: accessible to both !!authenticated users */}
        {/* <PublicRoute exact path='/dma' component={About} /> */}
        {/* <Route component={Login} exact path="/" /> */}
        <DmaPublicRoute exact path='/dma' component={LandingPage} />
        <DmaPublicRoute exact path='/dma/products' component={Products} />
        <DmaPublicRoute
          exact
          path='/dma/products/:id'
          component={ViewProduct}
        />
        <DmaPrivateRoute
          exact
          path='/dma/farmersorders'
          component={FarmersOrders}
        />
        <DmaPrivateRoute
          exact
          path='/dma/farmers/orders'
          component={CreateFarmerOrder}
        />
        <DmaPrivateRoute
          exact
          path='/dma/farmers/orders/payment/:paymentType/confirmation'
          component={PaymentConfimationPage}
        />

        <DmaPrivateRoute
          exact
          path='/dma/farmers/orders/payment/:paymentType'
          component={PaymentPage}
        />
        <DmaPrivateRoute exact path='/dma/stock' component={DmaStock} />
        <DmaPrivateRoute exact path='/dma/stock/:stockId/edit' component={EditDmaStock} />
        <DmaPrivateRoute exact path='/dma/stock/:stockId/history' component={DmaStockUpdateHistory} />
        <DmaPrivateRoute exact path='/dma/stock/add' component={AddDmaStock} />
        <CooperativeMultiPrivateRoute exact path='/dma/stock/requests' component={RequestStock} />
        <CooperativeMultiPrivateRoute exact path='/dma/stock/requests/:id' component={RequestStockDetails} />
        <DmaPrivateRoute exact path='/dma/cart' component={DmaCart} />
        <DmaPrivateRoute
          exact
          path='/dma/:id/awaiting-approval'
          component={ConfirmStatus}
        />
        <DmaPrivateRoute exact path='/dma/checkout' component={Checkout} />
        <DmaPrivateRoute exact path='/dma/dashboard' component={Dashboard} />
        <DmaPrivateRoute
          exact
          path='/dma/order/:id'
          component={FarmerOrderDetails}
        />
        <DmaPrivateRoute
          exact
          path='/dma/my-order/:id/:manufacturer'
          component={DMAOrderDetails}
        />
        <DmaPrivateRoute exact path='/dma/my-orders' component={DmaOrders} />
        <DmaPrivateRoute exact path='/dma/bulk-upload' component={BulkUpload} />
        <DmaPrivateRoute exact path={'/dma/profile'} component={DMAProfile} />
        {/* Cooperative DMA routes */}
        <CooperativeMultiPrivateRoute
          exact
          path='/branches/:id/details'
          component={CooperativeBranchDetails}
        />
        <CooperativeMultiPrivateRoute
          exact
          path='/branches/add'
          component={CooperativeAddBranch}
        />
        <CooperativeMultiPrivateRoute
          exact
          path='/branches'
          component={CooperativeBranchList}
        />
        <CooperativeMultiPrivateRoute
          exact
          path='/users/add'
          component={CooperativeAddUser}
        />
        <CooperativeMultiPrivateRoute
          exact
          path='/collection-centres'
          component={CooperativeCollectionCentreList}
        />

        <CooperativeMultiPrivateRoute
          exact
          path='/collection-centres/add'
          component={CooperativeAddCollectionCentre}
        />

        <CollectionCentreRoute
          exact
          path='/collection-centres/:id/details'
          component={CooperativeCollectionCentreDetails}
        />

        <CooperativeMultiPrivateRoute
          exact
          path='/collection-centres/users/add'
          component={CooperativeAddCollectionCentresUser}
        />

        <CollectionCentreRoute
          exact
          path='/transporters'
          component={CooperativeTransportersList}
        />

        <CooperativeMultiPrivateRoute
          exact
          path='/transporters/add'
          component={CooperativeAddTransporter}
        />
        <CollectionCentreRoute
          exact
          path='/transporters/:id/details'
          component={CooperativeTransporterDetails}
        />
        <CooperativePrivateRoute
          exact
          path='/cooperative/farmers'
          component={CooperativeFarmersList}
        />
        <CooperativePrivateRoute
          exact
          path='/cooperative/farmers/:id'
          component={CooperativeFarmersDetails}
        />
        <CooperativePrivateRoute
          exact
          path='/cooperative/add-farmer'
          component={CooperativeAddFarmers}
        />
        <CooperativePrivateRoute
          exact
          path='/cooperative/bulk-farmer-upload'
          component={CooperativeFarmerBulkUpload}
        />
        <CooperativePrivateRoute 
          exact
          path='/cooperative/farmers/:id/payment/add'
          component={CooperativeAddFarmerPayment}
        />
        <CooperativeMainBranchPrivateRoute
          exact
          path='/agrovets'
          component={Agrovets}
        />
        <CooperativeMainBranchPrivateRoute
          exact
          path='/agrovets/add'
          component={AddAgrovet}
        />
        {/* catch all invalid urls */}
        {/* <Route component={Error404} /> */}
      </Switch>
    </Suspense>
  </Router>
);

export default Routes;
