import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ErrorBoundary from "../components/ErrorBoundary";
import { AuthRouteProps } from "./layouts.model";
import { isDMA } from "../helpers/permissions";
import { getDMADetails, logout } from "../redux/actions/authActions";
import { useDispatch } from 'react-redux';

const AuthRoute: React.FC<AuthRouteProps> = ({
  component: Component,
  auth,
  ...rest
}) => {
  const user = auth.user as any;
  const dispatch = useDispatch();

  const [_, setDMADetails] = useState<any>(null)
  let newUser: boolean;
  if(auth.user?.isNewUser){
    newUser = auth.user.isNewUser
  }else{
    newUser=false
  }
  const group = user ? user["custom:group"] : '';

  const getDetails = async () => {
    const details = await getDMADetails(user.username);
    setDMADetails(details?.data || {});
  }

  const isVerifying = Boolean(localStorage.getItem('isVerifying'));
  const tempUsername = localStorage.getItem('tempUsername') || '';
  const authorizationPass = localStorage.getItem('authorizationPass') || '';

  const isRegistered = () => {
    try{
      return isVerifying
      || (tempUsername && authorizationPass);
    }catch(e){
      return false;
    }
  }

  useEffect(() => {
    if (group === 'dma') {
      getDetails();
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => {
        // if user is authenticated, redirect to homepage
        if (auth.isAuthenticated && !newUser && auth.verified) {
          if (
            isDMA(auth)
          ) {
            return (<Redirect to='/dma' />);
          } 
          return (
            <Redirect
              to='/manufacturers/dashboard'
            />
          );
        }
        else if(!auth.isAuthenticated  && !isRegistered() && !['/login', '/register', '/register-form', '/complete-registration'].includes(rest.path as string)){
          if(rest.path === '/dma-verification' && isRegistered()){
            return;
          }
          dispatch(logout());
          return (<Redirect to="/login" />);
        }
          return (
            <main className="" id="auth-route-layout">
              <ErrorBoundary>
                {Component && <Component {...props} />}
              </ErrorBoundary>
            </main>
          );
      }}
    />
  );
};

const mapStateToProps = ({ auth }: AuthRouteProps) => ({
  auth,
});

export default connect(mapStateToProps)(AuthRoute);
