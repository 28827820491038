import './Loaders.css';
import React from 'react';
import Loader from 'react-loader-spinner';

export const ButtonLoader = () => (
  <Loader type="Oval" color="black" width="26" height="26" />
);

export const PageLoader = () => (
  <div className="loader">
    <Loader type="Oval" color="black" width="100" height="100" timeout={10000}/>
  </div>
);
