import { API, graphqlOperation } from 'aws-amplify';
import { message as toast } from 'antd';
import { Storage } from 'aws-amplify';
import {
  CreateProductMutation,
  DeleteProductByIdMutation,
  GetProductByIdQuery,
  NestedProducts,
  Product,
  ProductInput,
  SearchProductsQuery,
  SearchProductsQueryVariables,
  GetCategoriesQuery,
} from '../../../API';
import { addCognitoAuth } from '../../../helpers/helpers';
import { GraphQLResult } from '@aws-amplify/api';
import { useCallback, useState } from 'react';
import {
  getProductById,
  searchProducts as searchProductsQuery,
  getCategories,
  } from '../../../graphql/queries';
import { useEffect } from 'react';
import {
  deleteProductById,
  createProduct as createProductQuery,
} from '../../../graphql/mutations';

import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

type AntUploadChange = UploadChangeParam<UploadFile<any>>;

const createProduct = async (
  product: ProductInput,
  onSuccess?: (data?: string | null, message?: string) => void,
  onError?: (message: string) => void,
) => {
  try {
    // const uploads = await uploadImage(product.image);

    const q = graphqlOperation(createProductQuery, { product });
    const { data } = (await API.graphql(
      addCognitoAuth(q),
    )) as GraphQLResult<CreateProductMutation>;
    const createdProduct = data?.createProduct;

    if (createdProduct) {
      onSuccess &&
        onSuccess(createdProduct.data, 'Create Product Successfully');
    } else {
      onError && onError('An error occurs while creating product');
    }
  } catch (e: any) {
    onError && onError(e?.message);
  }
};

const getProduct = async (productId: string) => {
  try {
    const q = graphqlOperation(getProductById, { productId });
    const { data } = (await API.graphql(
      addCognitoAuth(q),
    )) as GraphQLResult<GetProductByIdQuery>;

    if (data?.getProductById) {
      return {
        product: data?.getProductById,
        message: 'Success',
      };
    } else {
      throw new Error('Product does not exist');
    }
  } catch ({ message }) {
    return {
      product: null,
      message: message,
    };
  }
};

const searchProducts = async (
  params: SearchProductsQueryVariables | null = { limit: 12, page: 1, q: '' },
) => {
  try {
    const getUserName = JSON.parse(localStorage.getItem('user') || '{}')
    const q = graphqlOperation(
      searchProductsQuery,
      { ...params as SearchProductsQuery },
    );
    const { data } = (await API.graphql(
      addCognitoAuth(q),
    )) as GraphQLResult<SearchProductsQuery>;

    return { result: data?.searchProducts, message: 'search completed' };
  } catch (e) {
    return {
      result: null,
      message: 'Could not complete search',
    };
  }
};


const deleteProduct = async (productId?: string) => {
  try {
    const q = graphqlOperation(deleteProductById, { productId });

    (await API.graphql(
      addCognitoAuth(q),
    )) as GraphQLResult<DeleteProductByIdMutation>;

    return {
      message: 'Successfully deleted',
    };
  } catch (e: any) {
    return {
      message: e.message,
    };
  }
};

export const useProducts = () => {
  const [product, setProduct] = useState<Product | null | undefined>();
  const [message, setMessage] = useState('');
  const [images, setImages] = useState<any[]>([]);
  const [categories, setCategories] = useState<any>([]);
  const [isUploadingImages, setIsUploadingImages] = useState(false)

  const [paginatedProducts, setPaginatedProducts] = useState<{
    page?: number | null;
    total?: number | null;
    products?: Array<NestedProducts | null> | null;
  }>();

  const getProductDetails = async (productId: string) => {
    const result = await getProduct(productId);
    return result;
  };

  

  const getSingleProduct = useCallback(
    async (productId: string) => {
      const result = await getProduct(productId);

      setProduct(() => result.product);
      setMessage(() => result.message as string);
    },
    [setProduct, setMessage],
  );

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage('');
      }, 1000);
    }
  }, [message]);

  const fetchPaginatedProducts = useCallback(
    async (params?: SearchProductsQueryVariables | null) => {
      const { result, message: msg } = await searchProducts(params);

      setPaginatedProducts(() => ({
        products: result?.result,
        page: result?.page,
        total: result?.totalCount,
      }));
      setMessage(msg);
    },
    [],
  );

  const removeProduct = useCallback(async (productId?: string) => {
    const { message } = await deleteProduct(productId);

    setMessage(message);
    setPaginatedProducts((prev) => {
      return {
        ...prev,
        products:
          prev?.products &&
          prev.products.filter((p) => p?.productId !== productId),
      };
    });
  }, []);

  const handleImageChange = useCallback((info: AntUploadChange) => {
    console.log(info.file);
    const { status } = info.file;

    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }

    if (status === 'done') {
      toast.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      toast.error(`${info.file.name} file upload failed.`);
    }
  }, []);

  const getPublicStorageUrl = (key: string) => {
    return `https://${process.env.REACT_APP_PRODUCT_IMAGE_STORAGE}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/public/${key}`
  }

 const fetchCategories = async () =>  {
    try {

        const query = graphqlOperation(getCategories);
        const { data } = (await API.graphql(
          addCognitoAuth(query),
        )) as GraphQLResult<GetCategoriesQuery>;


        const items = data?.getCategories || [];
        setCategories(items);
    } catch (e) {
      console.log(e)
      throw e;
    }
};

const uploadImage = async (files: File[]) => {
  setIsUploadingImages(true)
  try {
    const fileKeys = files.map(file => `products/${file.name}`);
    await Promise.all(
      files.map((file) => {
        return Storage.put(`products/${file.name}`, file, {
          contentType: 'image/*',
          // authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '{}').accessToken}`
        });
      }));

    const images: Array<Object | string> = [];

    for(let i = 0; i < fileKeys.length; i++) {
     
      const url = getPublicStorageUrl(fileKeys[i])

      images.push(url);
    }
    return images
  } catch (e) {
    console.log({e})
    throw e
  }
}





  return {
    uploadImage,
    createProduct,
    handleImageChange,
    getSingleProduct,
    product,
    categories,
    message,
    fetchPaginatedProducts,
    paginatedProducts,
    removeProduct,
    fetchCategories,
    isUploadingImages,
    uploads: images,
    getProductDetails,
  };
};
