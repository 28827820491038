import './fileUpload.css';

import React from 'react';
import { Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { message } from 'antd';


type FileUploadTypes = {
    files: File[];
    setFiles: (val: File[]) => void;
    accept?: string[]
};

export const FileUpload: React.FC<FileUploadTypes> =  ({ files,accept, setFiles }) => {
    const onDrop: (acceptedFiles: File[]) => void = async (acceptedFiles) => {
        const existingFileNames: string[] = files.map((file) => file.name);
        const newFileNames: string[] = acceptedFiles.map((file) => file.name);

        const containsFileName: boolean = existingFileNames.some((file) => newFileNames.includes(file));
        if (!containsFileName) {
            if (getFilesSize([...files, ...acceptedFiles]) > 10) {
                message.error('Size is above allowed 10MB');
            } else {
                setFiles([...files, ...acceptedFiles]);
            }
        }
    };

    const getFilesSize: (files: File[]) => number = (files) => {
        return Math.floor(files.reduce((a, b) => a + b.size, 0) / 1024 / 1024);
    };

    const onClickRemove: (id: string) => void = (name) => {
        const newFiles = files.filter((file) => file.name !== name);
        setFiles(newFiles);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept:(accept || ['.zip', '.png', '.jpeg', '.pdf', '.jpg']),
    });
    return (
        <div
            className="p-4 mt-3 d-flex flex-column align-items-center justify-content-center drag-zone pointer"
            {...getRootProps()}
        >
            <Col md={3} className="text-align-center">
                <i className={`uploadIcon bi bi-cloud-arrow-up-fill`}></i>
                {/* <img alt="logo" src={logo} className={'mb-5'} width={150} /> */}
            </Col>
            <Col md={9} className="text-align-center">
                {files.length > 0 && (
                    <ol className="dropped-files">
                        {isDragActive ||
                            files.map((f) => {
                                return (
                                    <li key={`${f.name}image`} className="d-flex justify-content-between">
                                        <Col md={10}>{f.name} </Col>
                                        <Col
                                            md={2}
                                            className="red-color ml-3 text-align-center"
                                            onClick={() => onClickRemove(f.name)}
                                        >
                                            x
                                        </Col>
                                    </li>
                                );
                            })}
                    </ol>
                )}
                <div>
                    <div className="fs-16">Drag and Drop here</div>

                    <div>or</div>
                    <div className="black-color">
                        <input
                            style={{ display: 'none' }}
                            accept="image/x-png,image/jpeg,application/pdf"
                            multiple={true}
                            {...getInputProps()}
                        />
                        <div className="black-color">Click here to browse/select image(s)</div>
                    </div>
                </div>
            </Col>
        </div>
    );
};
