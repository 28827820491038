import { API, graphqlOperation } from "aws-amplify";
// import { GetWardsQuery } from "../../../API";
import {
  getAgrovetList,
  getCooperativeDmaPartners,
} from "../../../graphql/queries";
import { addCognitoAuth } from "../../../helpers/helpers";
import { GraphQLResult } from "@aws-amplify/api";
import { useState } from "react";
import { createDmaPartnership, removeDmaPartnership } from "../../../graphql/mutations";

export const useAgrovets = () => {
  const [agrovets, setAgrovets] = useState<any>([]);
  const [dmaAgrovets, setDmaAgrovets] = useState<any>([]);
  const [dmaAgrovetsTableData, setDmaAgrovetsTableData] = useState<any>([]);
  const [coopAgrovets, setCoopAgrovets] = useState<any>([]);
  //   const [singleBranch, setSingleBranch] = useState<any>();
  const [locations, setLocations] = useState<any>()
  //   const [mainBranch, setMainBranch] = useState<any>()

    const addAgrovet = async (id: string) => {
      try {
        const dmaObject = JSON.parse(localStorage.getItem("dma") || "{}");

        const userDetail = dmaObject.data.userDetail;

        const q = graphqlOperation(createDmaPartnership, {
          agrovetId: id,
          cooperativeId: userDetail['dmaId']
        });
        const { data } = (await API.graphql(
          addCognitoAuth(q)
        )) as any;
        return data.createDmaPartnership;
      } catch ({ message }) {
        return {
          data: null,
          message: message,
        };
      }
    }


  const removeAgrovet = async (dma: any) => {
    try {
        const dmaObject = JSON.parse(localStorage.getItem("dma") || "{}");

        const userDetail = dmaObject.data.userDetail;

        const q = graphqlOperation(removeDmaPartnership, {
          agrovetId: dma.dmaId,
          cooperativeId: userDetail['dmaId']
        });
        const { data } = (await API.graphql(
          addCognitoAuth(q)
        )) as any;

        const agro = coopAgrovets.filter((p:any) => p.dmaId !== dma.dmaId)
        setDmaAgrovets(agro)
        return data.removeDmaPartnership;
      } catch ({ message }) {
        return {
          data: null,
          message: message,
        };
      }
  };

  const getAgrovets = async () => {
    try {
      //   const userObject = JSON.parse(localStorage.getItem('user') || '{}')
      const q = graphqlOperation(getAgrovetList);
      const { data } = (await API.graphql(addCognitoAuth(q))) as GraphQLResult<any>;

      setAgrovets(data?.getAgrovetList?.data);
      // return data?.getBranches;
    } catch ({ message }) {
      return {
        data: null,
        message: message,
      };
    }
  };

  const getDMAAgrovets = async () => {
    try {
      const dmaObject = JSON.parse(localStorage.getItem("dma") || "{}");

      const userDetail = dmaObject.data.userDetail;
      const q = graphqlOperation(getCooperativeDmaPartners, {
        cooperativeId: userDetail["dmaId"],
      });
      const { data } = (await API.graphql(
        addCognitoAuth(q)
      )) as GraphQLResult<any>;
      setLocations(data?.getCooperativeDmaPartners?.data.map((p: any) => p.location))
      const coopAgro = data?.getCooperativeDmaPartners?.data;
      setCoopAgrovets(coopAgro);
      setDmaAgrovets(coopAgro);
      setDmaAgrovetsTableData(coopAgro);
      
    } catch ({ message }) {
      return {
        data: null,
        message: message,
      };
    }
  };

  return {
    getAgrovets,
    getDMAAgrovets,
    addAgrovet,
    dmaAgrovets,
    locations,
    agrovets,
    setDmaAgrovets,
    removeAgrovet,
    coopAgrovets,
    dmaAgrovetsTableData,
    setDmaAgrovetsTableData
  };
};
