import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { getDMADetails } from '../redux/actions/authActions';
// import {} from '../pages/'

export const KYCButton = () => {
  const dma = JSON.parse(localStorage.getItem('dma') || '');
  const [status, setStatus] = useState(dma?.data?.dmaDetail?.kyc_flow_state || '');

  const handleRefresh = async() => {
    if(status !== 'APPROVED'){
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const dmaDetails = cognitoUser.username && await getDMADetails(cognitoUser.username);
      console.log(dmaDetails);
      setStatus(dmaDetails?.data?.dmaDetail?.kyc_flow_state || '');
    }
  }

  return (
    <div onClick={() => handleRefresh()} >
        Refresh KYC Status: {status}
    </div>
  );
};
