import { SET_ITEM_TO_CART } from "./../actionTypes";
import { PayloadAction } from "@reduxjs/toolkit";
import { REMOVE_ITEM_FROM_CART, CLEAR_CART } from "../actionTypes";

type CartType = {
  cart?: {}[];
};
const initialState = {
  cart: {},
};

export const productReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.payload) {
    case CLEAR_CART:
      return { ...state, cart: {} };
    case SET_ITEM_TO_CART:
      return { ...state, cart: action.payload };
    case REMOVE_ITEM_FROM_CART:
      return { ...state, cart: action.payload };
    default:
      return state;
  }
};
